'use strict';

export class FindSuggestionViewModel {

    #label;
    #type;
    #matchType;
    #value;
    #textForSearchBox;
    #section;

    constructor(type, matchType, value, label, textForSearchBox, section) {
        this.#type = type;
        this.#matchType = matchType;
        this.#value = value;
        this.#label = label;
        this.#textForSearchBox = textForSearchBox;
        this.#section = section;
    }

    setMatchType(value) {
        this.#matchType = value;
    }

    getMatchType() {
        return this.#matchType;
    }

    setSection(value) {
        this.#section = value;
    }

    getSection() {
        return this.#section;
    }

    setLabel(value) {
        this.#label = value;
    }

    setValue(value) {
        this.#value = value;
    }

    setType(value) {
        this.#type = value;
    }

    setTextForSearchBox(value) {
        this.#textForSearchBox = value;
    }

    getLabel() {
        return this.#label;
    }

    getValue() {
        return this.#value;
    }

    getType() {
        return this.#type;
    }

    getTextForSearchBox() {
        return this.#textForSearchBox;
    }

    static sortByLabelComparator = (a, b) => {
        const labelA = a.getLabel().toLowerCase();
        const labelB = b.getLabel().toLowerCase();
        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }

        return 0;
    }
}
