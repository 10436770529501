'use strict';

import {Constants} from "./constants";
import {Utils} from "./utils";

export class AbstractPSWidgetController {

    constructor(widgetInstance) {
        Utils.setController(this);
        this._widgetInstance = widgetInstance;
        this._defaultLocation = this._widgetInstance.getAttribute('data-lumino-default-location');
        this._defaultSpecialty = this._widgetInstance.getAttribute('data-lumino-default-specialty');
        this._defaultCondition = this._widgetInstance.getAttribute('data-lumino-default-condition');
        this._defaultService = this._widgetInstance.getAttribute('data-lumino-default-service');
        this._googleAPIKey = this._widgetInstance.getAttribute('data-lumino-google-api-key');
        this._verticalOrientation = this._widgetInstance.hasAttribute('data-lumino-vertical-orientation');
        this._showHeaderText = !this._widgetInstance.hasAttribute('data-lumino-hide-header-text');
        this._useConfigSpecialtySorting = this._widgetInstance.hasAttribute('data-lumino-use-config-specialty-sorting');
        this._searchModes = [];

        this._languageCode = this.getLanguageCode(widgetInstance);
        this._uniqueId = Math.random().toString().slice(2,18);
    }

    fetchInitializationDataAndInit() {
        // ABSTRACT
    }

    initWidgetWithData(data) {

        this.updateSearchModesBasedOnApology(data.searchModesOnApology);
    }

    isValidWidgetParameters() {
        // ABSTRACT
    }

    sendSearchKeywordEvent() {
        // ABSTRACT
    }

    sendSearchErrorEvent() {
        // ABSTRACT
    }

    setSource() {
        // ABSTRACT
    }

    setSearchContextLocation() {
        // ABSTRACT
    }

    getNextAnalyticsSearchId() {
        // ABSTRACT
    }

    triggerSearchClickAnalytics(attempt) {
        // ABSTRACT
    }

    logNavigationEvent(actionCode) {
        // ABSTRACT
    }

    getSearchModes() {
        return this._searchModes;
    }

    getSearchModesArrayForInitValue(searchModesInitValue, enableFavourites) {

        let searchModes;
        if (!searchModesInitValue) {
            searchModes = this.getDefaultSearchModes();
        } else {
            searchModes = [];
            if (searchModesInitValue.indexOf(Constants.SEARCH_MODE_SPECIALTY) > -1) {
                searchModes.push(Constants.SEARCH_MODE_SPECIALTY);
            }
            if (searchModesInitValue.indexOf(Constants.SEARCH_MODE_NAME) > -1) {
                searchModes.push(Constants.SEARCH_MODE_NAME);
            }
            if (searchModesInitValue.indexOf(Constants.SEARCH_MODE_CONDITION) > -1) {
                searchModes.push(Constants.SEARCH_MODE_CONDITION);
            }
            if (searchModesInitValue.indexOf(Constants.SEARCH_MODE_SERVICE) > -1) {
                searchModes.push(Constants.SEARCH_MODE_SERVICE);
            }
        }

        if(enableFavourites) {
            searchModes.push(Constants.SEARCH_MODE_FAVOURITES);
        }

        return searchModes;
    }

    getDefaultSearchModes() {
        const searchModes = [];

        searchModes.push(Constants.SEARCH_MODE_SPECIALTY);
        searchModes.push(Constants.SEARCH_MODE_NAME);
        searchModes.push(Constants.SEARCH_MODE_CONDITION);
        searchModes.push(Constants.SEARCH_MODE_SERVICE);

        return searchModes;
    }

    removeWidgetOnError(errorMsg = "An error loading Lumino Widget has occurred, exiting.") {
        if(this._widgetInstance) {
            this._widgetInstance.parentNode.removeChild(this._widgetInstance);
            this._widgetInstance = null;
        }

        console.error(errorMsg);
    }


    fetchInitializationDataFromAPI(apiPath) {
        const xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = () => {
            if (xhttp.readyState === 4 && xhttp.status === 200) {
                if(xhttp.responseText) {
                    // On Success
                    this.initWidgetWithData(JSON.parse(xhttp.responseText));
                } else {
                    this.removeWidgetOnError();
                }
            }
        };
        xhttp.onerror = () => {
            this.removeWidgetOnError("Request error, exiting.");
        };
        xhttp.onloadend = () => {
            if(xhttp.status === 404) {
                this.removeWidgetOnError();
            }
        };

        xhttp.open("GET", apiPath, true);
        xhttp.send();
    }

    getForm() {
        return this._form;
    }

    createSearchEvent(attempt) {

        const form = this.getForm();

        const eventDetail = {};

        if(Constants.SEARCH_MODE_FAVOURITES === attempt) {
            eventDetail.searchMode = Constants.SEARCH_MODE_FAVOURITES;
        } else {

            const suggestionToUse = attempt.getSuggestionToUse();

            if (suggestionToUse.getType() === Constants.SUGGESTION_TYPE_SPECIALTY) {
                eventDetail.searchMode = Constants.SEARCH_MODE_SPECIALTY;
            } else if (suggestionToUse.getType() === Constants.SUGGESTION_TYPE_NAME) {
                eventDetail.searchMode = Constants.SEARCH_MODE_NAME;
            } else if (suggestionToUse.getType() === Constants.SUGGESTION_TYPE_CONDITION) {
                eventDetail.searchMode = Constants.SEARCH_MODE_CONDITION;
            } else if (suggestionToUse.getType() === Constants.SUGGESTION_TYPE_SERVICE) {
                eventDetail.searchMode = Constants.SEARCH_MODE_SERVICE;
            }

            eventDetail.location = form.getLocationValue();
            eventDetail.place = form.getLocationPlace();

            if (attempt.isExactMatch() && attempt.getSuggestionToUse().getMatchType() === Constants.SUGGESTION_MATCH_TYPE_LABEL) {
                eventDetail.suggestionSection = Constants.SUGGESTION_SECTION_EXACT_MATCH;
            } else {
                eventDetail.suggestionSection = suggestionToUse.getSection();
            }
            eventDetail.suggestionType = suggestionToUse.getType();
            eventDetail.suggestionValue = suggestionToUse.getValue();
            eventDetail.suggestionLabelForTextBox = suggestionToUse.getTextForSearchBox();
            eventDetail.searchTextBoxValue = this.getForm().getSearchedText();
        }


        return new CustomEvent('searchEvent', {
            bubbles: true,
            detail: eventDetail
        });
    }

    getMainDivElement() {
        return this._widgetInstance;
    }

    executeSearch() {
        // ABSTRACT
    }

    getSearchHost() {
        // ABSTRACT
    }

    hasMumEnabled() {
        // ABSTRACT
    }

    getLuminoFindAProviderPath() {

        if(this.getLanguageCode() === Constants.LANG_EN) {
            return "/en/find-a-health-care-provider/";
        } else {
            return "/fr/trouver-un-professionnel-de-la-sante/";
        }
    }

    isLocalhost(value) {
        if(value) {
            return (value.indexOf("localhost:") > -1);
        }

        return (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1");
    }

    createLuminoURL(suggestionToUse) {
        let redirectURL = "";

        if(this.isLocalhost()) {
            redirectURL = "/healthcommunity/pub/req/providersearch" + `${this.getLuminoFindAProviderPath()}`;
        } else {
            redirectURL = this.getSearchHost() + `${this.getLuminoFindAProviderPath()}`;
        }

        redirectURL += `?location=${encodeURIComponent(this.getForm().getLocationValue())}`;
        redirectURL += this.getSearchURLParameters(suggestionToUse);

        return redirectURL;
    }

    getSearchURLParameters(suggestionToUse) {

        let parameters = "";
        let searchMode = "";
        if(suggestionToUse.getType() === Constants.SUGGESTION_TYPE_SPECIALTY) {
            searchMode = Constants.SEARCH_MODE_SPECIALTY;
        } else if(suggestionToUse.getType() === Constants.SUGGESTION_TYPE_NAME) {
            searchMode = Constants.SEARCH_MODE_NAME;
        } else if(suggestionToUse.getType() === Constants.SUGGESTION_TYPE_CONDITION) {
            searchMode = Constants.SEARCH_MODE_CONDITION;
        } else if(suggestionToUse.getType() === Constants.SUGGESTION_TYPE_SERVICE) {
            searchMode = Constants.SEARCH_MODE_SERVICE;
        }

        searchMode = encodeURIComponent(searchMode);
        let searchValue = "";
        if(suggestionToUse.getValue()) {
            searchValue = encodeURIComponent(suggestionToUse.getValue());
        }
        parameters = "&searchmode=" + searchMode + "&searchvalue=" + searchValue;

        return parameters;
    }

    getLanguageCode() {
        let languageCode = this._widgetInstance.getAttribute('data-lumino-language');
        if(!languageCode) {
            console.warn("Warning: 'data-lumino-language' must be defined in the HTML element. Defaulting to 'EN' as no value was found.");
            return Constants.LANG_EN;
        }

        if(languageCode.toLowerCase() === 'fr') {
            languageCode = Constants.LANG_FR;
        } else {
            languageCode = Constants.LANG_EN;
        }

        return languageCode;
    }

    updateSearchModesBasedOnApology(searchModesOnApology) {

        let searchModes = this._searchModes;
        let modes = [];

        searchModes.forEach((mode) => {
            if (searchModesOnApology[mode]) {
                modes.push(mode);
            }
        });

        this._searchModes = searchModes.filter(item => !modes.includes(item));
    }
}
