'use strict';

import {LocationSuggestionViewModel} from "./location-suggestion-view-model";
import {LocationSuggestionsViewModel} from "./location-suggestions-view-model";


export class LocationSuggestionsViewModelBuilder {

    buildLocationSuggestionsViewModel(placePredictions) {

        const locationSuggestionsViewModel = new LocationSuggestionsViewModel();
        const locationSuggestions = [];
        if (!placePredictions || placePredictions.length <= 0) {
            return locationSuggestions;
        }

        for(let i = 0; i <= placePredictions.length - 1; i++) {

            const prediction = placePredictions[i];

            if(this.isPredictedLocationACountry(prediction) || this.isPredictedLocationAProvince(prediction)) {
                continue;
            }

            const labelMainText = prediction.structured_formatting.main_text;
            const labelSubText = prediction.structured_formatting.secondary_text;
            const locationSuggestion = new LocationSuggestionViewModel(prediction.place_id, prediction.description, labelMainText, labelSubText);

            locationSuggestions.push(locationSuggestion);
        }

        locationSuggestionsViewModel.setLocationSuggestions(locationSuggestions);

        return locationSuggestionsViewModel;
    }

    isPredictedLocationACountry(placePredictions) {
        if(!placePredictions || placePredictions.length <= 0) {
            return false;
        }

        if(!placePredictions.types || placePredictions.types.length <= 0) {
            return false;
        }

        return (!!placePredictions.types.includes("country")) && !placePredictions.structured_formatting.secondary_text;
    }

    isPredictedLocationAProvince(placePredictions) {
        if(!placePredictions || placePredictions.length <= 0) {
            return false;
        }

        if(!placePredictions.types || placePredictions.types.length <= 0) {
            return false;
        }

        return !!placePredictions.types.includes("administrative_area_level_1");
    }
}
