'use strict';

import {FindSuggestionsViewModel} from "./find-suggestions-view-model";
import {FindSuggestionViewModel} from "./find-suggestion-view-model";
import {Constants} from "../../constants";
import {Utils} from "../../utils";
import {FindSuggestionsSectionViewModel} from "./find-suggestions-section-view-model";
import Fuse from 'fuse.js';
import {PSWidgetLocalizedLabels} from "../../localized-labels";

export class FindSuggestionsViewModelBuilder {

    #language;
    #featureToggles;
    #localizedLabels;
    #suggestions;
    #useConfigSpecialtySorting;

    constructor(language, suggestionsData, featureToggles, useConfigSpecialtySorting) {
        this.#language = language;
        this.#featureToggles = featureToggles;
        this.#suggestions = suggestionsData;
        this.#useConfigSpecialtySorting = useConfigSpecialtySorting;

        this.#localizedLabels = new PSWidgetLocalizedLabels(this.#language);
        this.addLocalizedLabels();
    }


    isTextSearchEnabled() {
        if(!this.#featureToggles) {
            return false;
        }

        return this.#featureToggles.nameSearchEnabled;
    }

    isSpecialtySearchEnabled() {
        if(!this.#featureToggles) {
            return false;
        }

        return this.#featureToggles.specialtySearchEnabled;
    }

    isConditionSearchEnabled() {
        if(!this.#featureToggles) {
            return false;
        }

        return this.#featureToggles.conditionSearchEnabled;
    }

    isServiceSearchEnabled() {
        if(!this.#featureToggles) {
            return false;
        }

        return this.#featureToggles.serviceSearchEnabled;
    }

    getHintForUserInput(userInput) {
        if(userInput.length === 0) {
            return
        }

        if(userInput.length < Constants.SUGGESTION_MIN_INPUT_LENGTH) {
            return this.getLessThanMinCountHint();
        }

        return this.getSelectOptionHint();
    }

    static sortByMatchTypeComparator = (a, b) => {
        if (a.item.matchType === Constants.SUGGESTION_MATCH_TYPE_LABEL && b.item.matchType === Constants.SUGGESTION_MATCH_TYPE_RELATED_TERM) {
            return -1;
        }
        if (a.item.matchType === Constants.SUGGESTION_MATCH_TYPE_RELATED_TERM && b.item.matchType === Constants.SUGGESTION_MATCH_TYPE_LABEL) {
            return 1;
        }

        return 0;
    }

    getSuggestionsForUserInput(userInput) {

        const suggestionsViewModel = new FindSuggestionsViewModel();

        userInput = userInput.trim();
        const normalizedUserInput = Utils.normalizeString(userInput).toLowerCase();
        let hasAtLeastOneMatch = false;

        if(normalizedUserInput && normalizedUserInput.length >= Constants.SUGGESTION_MIN_INPUT_LENGTH) {

            const allPossibleValues = [];
            allPossibleValues.push(...this.#suggestions);

            const options = {
                isCaseSensitive: false,
                includeMatches: true,
                findAllMatches: true,
                includeScore: true,
                threshold: fuzzyMatchScore,
                keys: ["term"]
            };
            const fuse = new Fuse(allPossibleValues, options);

            let fuzzySearchResult = fuse.search(normalizedUserInput);

            fuzzySearchResult = fuzzySearchResult.sort(FindSuggestionsViewModelBuilder.sortByMatchTypeComparator);

            if(this.isSpecialtySearchEnabled()) {
                const section = this.buildSectionFromMatches(fuzzySearchResult,
                    this.#localizedLabels.getLocalizedLabel("specialtySectionTitle"),
                    Constants.SUGGESTION_SECTION_SUGGESTED_SPECIALTIES,
                    Constants.SUGGESTION_TYPE_SPECIALTY);

                if(section.getSuggestions().length > 0) {
                    suggestionsViewModel.addSection(section);
                    hasAtLeastOneMatch = true;
                }
            }

            if(this.isConditionSearchEnabled()) {
                const section = this.buildSectionFromMatches(fuzzySearchResult,
                    this.#localizedLabels.getLocalizedLabel("conditionsSectionTitle"),
                    Constants.SUGGESTION_SECTION_SUGGESTED_CONDITIONS,
                    Constants.SUGGESTION_TYPE_CONDITION);
                if(section.getSuggestions().length > 0) {
                    suggestionsViewModel.addSection(section);
                    hasAtLeastOneMatch = true;
                }
            }

            if(this.isServiceSearchEnabled()) {
                const section = this.buildSectionFromMatches(fuzzySearchResult,
                    this.#localizedLabels.getLocalizedLabel("servicesSectionTitle"),
                    Constants.SUGGESTION_SECTION_SUGGESTED_SERVICES,
                    Constants.SUGGESTION_TYPE_SERVICE);
                if(section.getSuggestions().length > 0) {
                    suggestionsViewModel.addSection(section);
                    hasAtLeastOneMatch = true;
                }
            }

            if(this.isTextSearchEnabled()) {
                const section = this.buildNameSuggestionSectionFromUserInput(userInput);
                if(section.getSuggestions().length > 0) {
                    suggestionsViewModel.addSection(section);
                }
            }
        }

        if(this.isSpecialtySearchEnabled() && !hasAtLeastOneMatch) {
            suggestionsViewModel.addSection(this.buildAllSpecialtiesSuggestion());
        }

        suggestionsViewModel.setHint(this.getHintForUserInput(normalizedUserInput));

        return suggestionsViewModel;
    }

    buildNameSuggestionSectionFromUserInput(userInput) {
        let nameSection = new FindSuggestionsSectionViewModel();
        nameSection.setIsBasedOnUserInput(true);
        nameSection.setLabel(this.getNameSuggestionSectionTitle());
        nameSection.setType(Constants.SUGGESTION_SECTION_SUGGESTED_NAME);

        let nameSuggestions = [];
        const nameSuggestion = this.buildNameSuggestionForText(userInput);
        nameSuggestions.push(nameSuggestion);
        nameSection.setSuggestions(nameSuggestions);

        return nameSection;
    }

    buildAllSpecialtiesSuggestion() {
        const section = new FindSuggestionsSectionViewModel();
        section.setIsBasedOnUserInput(false);
        section.setLabel(this.#localizedLabels.getLocalizedLabel("allSpecialtiesSectionTitle"));
        section.setType(Constants.SUGGESTION_SECTION_ALL_SPECIALTIES);

        let addedSpecialtyCodes = [];
        const allSpecialtySuggestions = [];
        for(let i = 0; i <= this.#suggestions.length - 1; i++) {

            const suggestion = this.#suggestions[i];
            if(suggestion.type === Constants.SUGGESTION_TYPE_SPECIALTY && !addedSpecialtyCodes.includes(suggestion.code)) {

                allSpecialtySuggestions.push(this.getSuggestionViewModelForSuggestionResultItem(suggestion, Constants.SUGGESTION_SECTION_ALL_SPECIALTIES));
                addedSpecialtyCodes.push(suggestion.code);
            }
        }

        if(this.#useConfigSpecialtySorting) {
            section.setSuggestions(allSpecialtySuggestions);
        } else {
            const sortedAllSpecialtySuggestions = allSpecialtySuggestions.sort(FindSuggestionViewModel.sortByLabelComparator);
            section.setSuggestions(sortedAllSpecialtySuggestions);
        }

        return section;
    }

    buildNameSuggestionForText(text) {
        return new FindSuggestionViewModel(Constants.SUGGESTION_TYPE_NAME, Constants.SUGGESTION_MATCH_TYPE_LABEL, text, this.getNameSuggestionLabel(text), text, Constants.SUGGESTION_SECTION_SUGGESTED_NAME);
    }

    getNameSuggestionLabel(userInput) {
        return `${this.#localizedLabels.getLocalizedLabel("nameSuggestionContent")} <strong>${userInput}</strong>`;
    }

    buildSectionFromMatches(result, label, sectionType, suggestionType) {

        const section = new FindSuggestionsSectionViewModel();

        section.setIsBasedOnUserInput(true);
        section.setLabel(label);
        section.setType(sectionType);

        const addedCodes = [];

        const suggestionViewModels = [];
        for(let i = 0; i <= result.length - 1; i++) {

            const item = result[i].item;
            if(item.type === suggestionType && !addedCodes.includes(item.code)) {
                suggestionViewModels.push(this.getSuggestionViewModelForSuggestionResultItem(item, sectionType));
                addedCodes.push(item.code);
            }
        }

        section.setSuggestions(suggestionViewModels);

        return section;
    }

    getSuggestionViewModelForSuggestionResultItem(item, sectionType) {
        return new FindSuggestionViewModel(item.type, item.matchType, item.code, item.label, item.label, sectionType);
    }

    buildSuggestionFromCode(code, suggestionType, sectionType, matchType) {

        let suggestionViewModel = null;

        if(suggestionType === Constants.SUGGESTION_TYPE_NAME) {

            suggestionViewModel = new FindSuggestionViewModel(suggestionType, matchType, code, code, this.getNameSuggestionLabel(code), Constants.SUGGESTION_SECTION_SUGGESTED_NAME);

        } else {

            let i = 0;
            while (!suggestionViewModel && i <= this.#suggestions.length - 1) {

                const suggestion = this.#suggestions[i];
                if (suggestion.code === code && suggestion.type === suggestionType) {
                    suggestionViewModel = new FindSuggestionViewModel(suggestionType, matchType, suggestion.code,
                        suggestion.label, suggestion.label, sectionType);
                }

                i++;
            }
        }

        return suggestionViewModel;
    }

    getNameSuggestionSectionTitle () {
        return this.#localizedLabels.getLocalizedLabel("nameSectionTitle");
    }

    getLessThanMinCountHint () {
        return this.#localizedLabels.getLocalizedLabel("lessThanMinCountHint");
    }

    getSelectOptionHint () {
        return this.#localizedLabels.getLocalizedLabel("selectOptionHint");
    }

    addLocalizedLabels() {
        this.#localizedLabels.addLocalizedLabel("lessThanMinCountHint", "Keep typing...", "Continuez à taper...");
        this.#localizedLabels.addLocalizedLabel("selectOptionHint", "Select one of the following options:", "Choisissez une des options suivantes :");

        this.#localizedLabels.addLocalizedLabel("specialtySectionTitle", "Specialties", "Spécialités");
        this.#localizedLabels.addLocalizedLabel("conditionsSectionTitle", "Conditions", "Problèmes de santé");
        this.#localizedLabels.addLocalizedLabel("servicesSectionTitle", "Services", "Services");
        this.#localizedLabels.addLocalizedLabel("nameSectionTitle", "Provider name", "Nom du professionnel");
        this.#localizedLabels.addLocalizedLabel("allSpecialtiesSectionTitle", "All specialties (A - Z)", "Toutes les spécialités (A - Z)");

        this.#localizedLabels.addLocalizedLabel("nameSuggestionContent", "Search providers named", "Rechercher des professionnels nommés");
    }
}
