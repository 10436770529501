'use strict';

import {PSWidgetSuggestionsDropdown} from "../suggestions-dropdown";
import poweredByGoogleLogo from "../../../images/powered-by-google.png";
import {PSWidgetGeolocationButton} from "../../geolocation-button";

export class PSWidgetLocationSuggestionsDropdown extends PSWidgetSuggestionsDropdown {
    #locationSuggestionsViewModel;
    #showGeolocation;
    #geolocationButton;
    #minimumDropdownHeight = 265;

    constructor(language, uniqueId, locationSuggestionsViewModel, showGeolocation) {
        super(language, uniqueId);

        this.#locationSuggestionsViewModel = locationSuggestionsViewModel;
        this.#showGeolocation = showGeolocation;
    }

    bindToDOM(parentDOMElement) {
        super.bindToDOM(parentDOMElement);

        if(this.#showGeolocation) {
            this.#geolocationButton = new PSWidgetGeolocationButton(this._language, this._uniqueId);
            this.#geolocationButton.bindToDOM(this.getGeolocationWrapper());
        }

        this.updateListOfSuggestionButtons();
        this.bindEventListeners();
    }

    bindEventListeners() {
        this.getListOfSuggestionButtons().forEach((suggestion) => {
            suggestion.addEventListener("click", (e) => {
                e.preventDefault();

                const eventDetail = {
                    text: e.currentTarget.getAttribute("data-lumino-location-suggestion-text"),
                    placeId: e.currentTarget.getAttribute("data-lumino-location-suggestion-placeid")
                };

                this._domElement.dispatchEvent(new CustomEvent('suggestionClicked', { bubbles: true, detail: eventDetail }));
            });
        })
    }

    getMinimumDropdownHeight() {
        return this.#minimumDropdownHeight;
    }

    closeDropdownWhenClickingOutside(e) {
        if(!e.target.closest('[data-lumino-widget-id="lumino-widget-location-field"]') && !e.target.closest('[data-prvsrch-modal]')) {
            this._domElement.dispatchEvent(new CustomEvent('closeLocationSuggestion', { bubbles: true }));
        }
    }

    getListOfSuggestionButtons() {
        return this._domElement.querySelectorAll('[data-lumino-widget-id="lumino-location-suggestion"]');
    }

    getListOfHighlightableButtons() {
        return this._domElement.querySelectorAll('[data-lumino-widget-id="lumino-location-suggestion"], [data-lumino-widget-id="lumino-widget-geolocation-btn"]');
    }

    getGeolocationWrapper() {
        return this._domElement.querySelector('[data-lumino-widget-id="lumino-location-geolocation-wrapper"]');
    }

    getGeolocationButton() {
        return this.#geolocationButton;
    }

    renderGeolocationWrapper() {
        if(!this.#showGeolocation) {
            return '';
        }

        return `<div data-lumino-widget-id="lumino-location-geolocation-wrapper" class="lumino-location-geolocation-wrapper"></div>`;
    }

    buildHighlightedGoogleLocationSuggestion(suggestion) {
        if(!suggestion) {
            return ``;
        }

        return `${this.buildHighlightedGoogleLocationSuggestionMainText(suggestion)} ${this.buildHighlightedGoogleLocationSuggestionSubText(suggestion)}`;
    }

    buildHighlightedGoogleLocationSuggestionMainText(suggestion) {
        if(!suggestion) {
            return ``;
        }

        if(!suggestion.getLabelMainText()) {
            return ``;
        }

        return `<span data-lumino-widget-id="lumino-location-suggestion-main-text" class="lumino-location-suggestion-main-text">${suggestion.getLabelMainText()}</span>`;
    }

    buildHighlightedGoogleLocationSuggestionSubText(suggestion) {
        if(!suggestion) {
            return ``;
        }

        if(!suggestion.getLabelSubText()) {
            return ``;
        }

        return `<span data-lumino-widget-id="lumino-location-suggestion-secondary-text" class="lumino-location-suggestion-secondary-text">${suggestion.getLabelSubText()}</span>`;
    }

    renderGoogleLocationSuggestions() {
        if(!this.#locationSuggestionsViewModel) {
            return ``;
        }

        let suggestionsOutput = `<div data-lumino-widget-id="lumino-location-suggestions-inner-wrapper" class="lumino-location-suggestions-inner-wrapper">`;

        this.#locationSuggestionsViewModel.getLocationSuggestions().forEach((suggestion, index) => {
            suggestionsOutput += `
                    <button
                        data-lumino-widget-id="lumino-location-suggestion" 
                        class="lumino-location-suggestion"
                        data-lumino-location-suggestion-text="${suggestion.getLabel()}" 
                        data-lumino-location-suggestion-placeid="${suggestion.getPlaceId()}" role="option" id="${suggestion.getPlaceId()}-${index}-${this._uniqueId}">${this.buildHighlightedGoogleLocationSuggestion(suggestion)}</button>
                `;
        });

        suggestionsOutput += `</div>`;

        return suggestionsOutput;
    }

    renderPoweredByGoogleLogo() {
        if(!this.#locationSuggestionsViewModel) {
            return ``;
        }

        return `<img class="lumino-location-suggestions-powered-by-google" data-lumino-widget-id="lumino-location-suggestions-powered-by-google" src=${poweredByGoogleLogo} alt="${this.getLocalizedLabel("poweredByGoogle")}" />`;
    }

    render() {
        return `<div data-lumino-widget-id="lumino-location-suggestions-wrapper" role="listbox" class="lumino-location-suggestions-wrapper" id="lumino-location-suggestions-wrapper-${this._uniqueId}">
                    ${this.renderGeolocationWrapper()}
                    ${this.renderGoogleLocationSuggestions()}
                    ${this.renderPoweredByGoogleLogo()}
                </div>`;
    }
    
    addLocalizedLabels() {
        this.addLocalizedLabel("poweredByGoogle","powered by Google","propuls&eacute; par Google");
    }
}
