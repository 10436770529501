'use strict';

import {PSWidgetBasicComponent} from "../basic-component";
import {Utils} from "../utils";
import {disablePageScroll, enablePageScroll} from 'scroll-lock';

export class PSWidgetCombobox extends PSWidgetBasicComponent {
    constructor(language, uniqueId) {
        super(language);

        this._uniqueId = uniqueId;
    }

    bindToDOM(parentDOMElement) {
        super.bindToDOM(parentDOMElement);
    }

    bindEventListeners() {
        this.getDOMElement().addEventListener('clearClicked', (e) => {
            e.stopPropagation();
            this.onClearButtonClick();
        });

        this.getDOMElement().addEventListener("setActiveAriaDescendant", (e) => {
            this.getInputField().updateAttribute("aria-activedescendant", e.detail)
        });

        this.getInputField().getTextboxElement().addEventListener('focusin', (e) => {
            e.stopPropagation();
            this.onTextFieldComesIntoFocus();
        });

        this.getInputField().getTextboxElement().addEventListener('click', (e) => {
            e.stopPropagation();
            this.onTextFieldComesIntoFocus();
        });

        this.getInputField().getDOMElement().addEventListener('textInputBackButtonClicked', () => {
            this.closeSuggestions(true, true);
        });

        this.getInputField().getDOMElement().addEventListener('textInputShiftTabKey', () => {
            if(!Utils.isMobilePhone()) {
                this.closeSuggestions(false, true);
            }
        });

        this.getInputField().getDOMElement().addEventListener('textInputUpKey', () => {
            if(!this.getSuggestionsDropdown()) {
                return;
            }

            this.getSuggestionsDropdown().navigateToPreviousSuggestion();
        });

        this.getInputField().getDOMElement().addEventListener('textInputDownKey', () => {
            if(!this.getSuggestionsDropdown()) {
                return;
            }

            this.getSuggestionsDropdown().navigateToNextSuggestion();
        });

        this.getInputField().getDOMElement().addEventListener('textInputEnterKey', () => {
            this.enterKeyWasPressedOnSuggestion();
        });

        this.getInputField().getDOMElement().addEventListener('textInputTabKey', (e) => {
            e.stopPropagation();

            if(!this.getSuggestionsDropdown()) {
                return;
            }

            this.closeSuggestions(false, true);
        });

        this.getInputField().getDOMElement().addEventListener('textInputEscKey', () => {
            this.closeSuggestions(true, true);
        });

        this.getInputField().getDOMElement().addEventListener('backButtonShiftTabKey', () => {
           this.closeSuggestions(false, true);
        });
    }

    updateSuggestionsWrapperScreenPosition() {
        if(!this.getSuggestionsDropdown() || !this.getSuggestionsDropdown().getDOMElement() || !this.getInputField() || !this.getInputField().getDOMElement() || !this.getInputField().getDOMElement().offsetHeight) {
            return ;
        }

        if(!Utils.isMobilePhone()) {
            this.adjustForMobileLayoutOffFocus(true);
        }

        let offsetHeight = this.getInputField().getDOMElement().offsetHeight;
        this.getSuggestionsDropdown().getDOMElement().style.top = (offsetHeight+1)+"px";
    }

    bindResizeEvent() {
        if(!this.getInputField() || !this.getInputField().getDOMElement()) {
            return;
        }
        window.addEventListener("resize",this.updateSuggestionsWrapperScreenPosition.bind(this));
    }

    onClearButtonClick() {
        this.getDOMElement().dispatchEvent(new CustomEvent('textInputTextRemoved', {bubbles: true}));

        this.getInputField().setTextFieldValue("");
        this.getInputField().removeErrorBox();
        this.resetSelectedSuggestion();

        if(!this.getSuggestionsDropdown()) {
            this.adjustForMobileLayoutOnFocus();
        }

        this.closeSuggestions(false, false);
        this.showSuggestions();

        this.getInputField().textFieldFocus();
        this.getInputField().showHideClearButton();
    }

    enterKeyWasPressedOnSuggestion() {
        // Override me
    }

    onTextFieldComesIntoFocus() {
        // Override me
    }

    getInputField() {
        // Override me
    }

    getSuggestionsDropdown() {
        // Override me
    }

    setSuggestionsDropdown() {
        // Override me
    }

    resetSelectedSuggestion() {
        // Override me
    }

    closeSuggestions(focusOnTextbox, adjustLayoutForOffFocus) {
        if(this.getSuggestionsDropdown() == null) {
            return;
        }

        if(adjustLayoutForOffFocus) {
            this.adjustForMobileLayoutOffFocus();
        }


        this.getInputField().clearAttribute("aria-activedescendant");
        this.getInputField().updateAttribute("aria-expanded", "false");

        this._domElement.parentElement.classList.remove("opened");

        if(focusOnTextbox) {
            this.getInputField().textFieldFocus();
        }

        this.getSuggestionsDropdown().destroy();
        this.setSuggestionsDropdown(null);
    }

    adjustForMobileLayoutOnFocus() {
        if(!Utils.isMobilePhone()) {
            return;
        }

        window.luminoWidgetPendingAnimation = false;
        this._domElement.style.transitionDuration = `150ms`;
        window.visualViewport.addEventListener("scroll", this.adjustMobileInputWhenVirtualKeyboardOpensHandler);
        window.visualViewport.addEventListener("resize", this.adjustMobileInputWhenVirtualKeyboardOpensHandler);

        disablePageScroll();
    }

    adjustMobileInputWhenVirtualKeyboardOpensHandler = this.adjustMobileInputWhenVirtualKeyboardOpens.bind(this);

    adjustMobileInputWhenVirtualKeyboardOpens() {
        if (window.luminoWidgetPendingAnimation) return;
        window.luminoWidgetPendingAnimation = true;

        requestAnimationFrame(() => {
            window.luminoWidgetPendingAnimation = false;
            this._domElement.style.transform = `translateY(${ Math.max(0, window.visualViewport.offsetTop)}px)`;
        });
    }

    adjustForMobileLayoutOffFocus(forceExecute=false) {
        if(!Utils.isMobilePhone() && !forceExecute) {
            return;
        }

        window.visualViewport.removeEventListener("scroll", this.adjustMobileInputWhenVirtualKeyboardOpensHandler);
        window.visualViewport.removeEventListener("resize", this.adjustMobileInputWhenVirtualKeyboardOpensHandler);
        this._domElement.style.transitionDuration = `0ms`;
        this._domElement.style.transform = `none`;

        enablePageScroll();
    }
}
