'use strict';

import '../../scss/external/styles-loader.scss';
import {ExternalPSWidgetController} from "./external-controller";

window.externalFormWidgetInit = function() {
    const containerElement = document.body.querySelectorAll("div[data-lumino-widget='ProviderSearchLauncher']");

    containerElement.forEach((externalWidgetInstance) => {

        // Don't load widget again if 'luminoFormWidgetInit' is called but widget was already initialized
        if(externalWidgetInstance.hasAttribute("data-lumino-widget-initialized")) {
            return;
        }

        const externalWidgetController = new ExternalPSWidgetController(externalWidgetInstance);

        if(externalWidgetController.isValidWidgetParameters()) {
            externalWidgetController.fetchInitializationDataAndInit();
        } else {
            externalWidgetController.removeWidgetOnError("An error validating Lumino Widget has occurred, exiting.");
        }
    });
};

if (document.readyState === "complete" || (document.readyState !== "loading" && !document.documentElement.doScroll)) {
    externalFormWidgetInit();
} else {
    document.addEventListener("DOMContentLoaded", externalFormWidgetInit);
}
