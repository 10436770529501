'use strict';

import {PSWidgetBasicComponent} from "../basic-component";
import {PSWidgetErrorBox} from "../error-box";
import {Utils} from "../utils";

export class PSWidgetTextField extends PSWidgetBasicComponent {

    constructor(language, uniqueId) {
        super(language);
        this._uniqueId = uniqueId;
    }

    bindToDOM(parentDOMElement) {
        super.bindToDOM(parentDOMElement);
    }

    bindEventListeners() {
        this.bindKeyboardNavigationEvents();

        this.getTextboxElement().addEventListener('input', (e) => {
            this.dispatchTextChangedEvent(e);
            this.showHideClearButton();
        });

        this.getTextboxBackButton().addEventListener('click', (e) => {
            e.stopPropagation();
            this.getDOMElement().dispatchEvent(new CustomEvent('textInputBackButtonClicked', { bubbles: true }));
        });
    }

    bindKeyboardNavigationEvents() {
        this.getTextboxBackButton().addEventListener('keydown', (e) => {
            if (e.key === "Tab" && e.shiftKey) {
                this._domElement.dispatchEvent(new CustomEvent('backButtonShiftTabKey', {bubbles: true}));
            }
        });

        this.getTextboxElement().addEventListener('keydown', (e) => {
            const key = e.key;

            if (key === "Backspace" || key === "Delete") {
                this.dispatchTextRemovalEvent();
            }

            if (key === "ArrowDown") {
                e.preventDefault();
                this._domElement.dispatchEvent(new CustomEvent('textInputDownKey', {bubbles: true}));
            }

            if (key === "ArrowUp") {
                e.preventDefault();
                this._domElement.dispatchEvent(new CustomEvent('textInputUpKey', {bubbles: true}));
            }

            if (key === "Enter") {
                e.preventDefault();
                this._domElement.dispatchEvent(new CustomEvent('textInputEnterKey', {bubbles: true}));
            }

            if (key === "Tab") {
                if(e.shiftKey) {
                    this._domElement.dispatchEvent(new CustomEvent('textInputShiftTabKey', {bubbles: true}));
                }
                else {
                    this._domElement.dispatchEvent(new CustomEvent('textInputTabKey', {bubbles: true}));
                }
            }

            if (e.key === "Escape") {
                this._domElement.dispatchEvent(new CustomEvent('textInputEscKey', {bubbles: true}));
            }
        });
    }

    showHideClearButton() {
        if(this._textboxElement.value === "") {
            this._textboxElement.classList.remove("lumino-has-clear-icon");
            this.getClearFieldButton().hideClearButton();
        } else {
            this._textboxElement.classList.add("lumino-has-clear-icon");
            this.getClearFieldButton().showClearButton();
        }
    }

    dispatchTextRemovalEvent() {
        this._domElement.dispatchEvent(new CustomEvent('textInputTextRemoved', {bubbles: true}));
        this.removeErrorBox();
    }

    dispatchTextChangedEvent(e) {
        this._domElement.dispatchEvent(new CustomEvent('textInputTextChanged', { bubbles: true, detail: { inputValue: e.target.value, inputType: e.inputType } }));
        this.removeErrorBox();
    }

    textFieldFocus() {
        this._textboxElement.focus();
    }

    clearAttribute(attributeName) {
        if (!attributeName) {
            return;
        }
        this.getTextboxElement().removeAttribute(attributeName);
    }

    updateAttribute(attributeName, attributeVal) {
        if (!attributeName || (!attributeVal && attributeVal !== false && attributeVal !== 0)) {
            return;
        }
        this.getTextboxElement().setAttribute(attributeName, attributeVal);
    }

    inputValidation() {
        // Abstract
        throw "input validation required";
    }

    isValidInput(dontShowErrors) {
        this.removeErrorBox();

        return this.inputValidation(dontShowErrors);
    }

    isEmptyField() {
        return this._textboxElement.value.trim().length === 0;
    }

    getErrorMessage() {
        if(!this._errorBox) {
            return "";
        }

        return this._errorBox.getErrorBoxContent();
    }

    renderErrorBox(customLabel) {
        this.removeErrorBox();

        this._errorBox = new PSWidgetErrorBox(this._language, customLabel, this.getFieldIdentifier());
        this._errorBox.bindToDOM(this._textboxWrapper);

        this.addErrorClassToInputElement(this._textboxElement);
        this.addAriaLabelledByToInputElement(this._textboxElement);
    }

    removeErrorBox() {
        if(this._errorBox) {
            this._errorBox.destroy();
            this._errorBox = null;

            this.removeErrorClassFromInputElement(this._textboxElement);
            this.removeAriaLabelledByToInputElement(this._textboxElement);
        }
    }

    addAriaLabelledByToInputElement(inputElement) {
        if(!inputElement) {
            return;
        }

        inputElement.setAttribute("aria-describedby", `prvsrch-${this.getFieldIdentifier()}`);
    }

    removeAriaLabelledByToInputElement(inputElement) {
        if(!inputElement) {
            return;
        }

        inputElement.removeAttribute("aria-describedby");
    }

    addErrorClassToInputElement(inputElement) {
        if(!inputElement) {
            return;
        }

        inputElement.classList.add("lumino-widget-input-error");
    }

    removeErrorClassFromInputElement(inputElement) {
        if(!inputElement) {
            return;
        }

        inputElement.classList.remove("lumino-widget-input-error");
    }

    setTextFieldValue(value) {
        if(!this._textboxElement) {
            return;
        }

        this._textboxElement.value = value;
        this.showHideClearButton();
    }

    getTextFieldValue() {
        if(!this._textboxElement) {
            return;
        }

        return Utils.sanitizeString(this._textboxElement.value);
    }

    getTextboxElement() {
        return this._textboxElement;
    }

    getClearFieldButton() {
        // Override me
    }

    getTextboxBackButton() {
        // Override me
    }

    renderTextboxBackButton() {
        return `<button type="button" aria-label="${this.getLocalizedLabel("backAriaLabel")}" class="lumino-widget-textbox-back-btn" data-lumino-widget-id="lumino-widget-textbox-back-btn">${ this.getTextboxBackSVGString() }</button>`;
    }

    getTextboxBackSVGString() {
        return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" role="img"><title>${this.getLocalizedLabel("backAriaLabel")}</title><path class="cls-1" d="M9,18L0,9,9,0l1.18,1.18L3.21,8.16h14.79v1.69H3.21l6.98,6.98-1.18,1.18Z"/></svg>`;
    }

    render() {
        // Abstract
        throw "input render function required"
    }
}
