'use strict';
import {PSWidgetBasicComponent} from "./basic-component";

export class PSWidgetErrorBox extends PSWidgetBasicComponent {
    #errorLabel;
    #fieldIdentifier;

    constructor(language, errorLabel, fieldIdentifier) {
        super(language);

        this.#errorLabel = errorLabel;
        this.#fieldIdentifier = fieldIdentifier;
    }

    bindToDOM(parentDOMElement) {
        super.bindToDOM(parentDOMElement);
    }

    renderErrorMessage() {
        if(!this.#errorLabel) {
            throw "Error label needs to have content!";
        }

        return this.#errorLabel;
    }

    getErrorBoxContent() {
        return this._domElement.querySelector('[data-lumino-widget-id="lumino-widget-error-box-content"]').innerHTML;
    }

    render() {
        return `<div class="lumino-widget-error-box-wrapper" data-lumino-widget-id="lumino-widget-error-box-wrapper">
                    ${ this.getWarningSVGString() }
                    <span id="prvsrch-${this.#fieldIdentifier}" data-lumino-widget-id="lumino-widget-error-box-content">${ this.renderErrorMessage() }</span>
                </div>`;
    }

    getWarningSVGString() {
        return `<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 512"><path class="cls-1" d="M160,432c0,44.11-35.89,80-80,80S0,476.11,0,432s35.89-80,80-80,80,35.89,80,80ZM9.26,25.2l13.6,272c.64,12.77,11.18,22.8,23.97,22.8H113.17c12.79,0,23.33-10.03,23.97-22.8L150.74,25.2c.68-13.71-10.24-25.2-23.97-25.2H33.23C19.5,0,8.58,11.49,9.26,25.2Z"/></svg>`;
    }
}
