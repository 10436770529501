'use strict';

export class FindSuggestionsSectionViewModel {

    #label;
    #suggestions;
    #isBasedOnUserInput;
    #type;

    setType(value) {
        this.#type = value;
    }

    getType() {
        return this.#type;
    }

    getSuggestions() {
        return this.#suggestions;
    }

    setSuggestions(suggestions) {
        this.#suggestions = suggestions;
    }

    getLabel() {
        return this.#label;
    }

    setLabel(label) {
        this.#label = label;
    }

    setIsBasedOnUserInput(value) {
        this.#isBasedOnUserInput = value;
    }

    isBasedOnUserInput() {
        return this.#isBasedOnUserInput;
    }


}
