'use strict';

import {ExternalPSWidgetForm} from "./external-form";
import {AbstractPSWidgetController} from "../abstract-controller";

export class ExternalPSWidgetController extends AbstractPSWidgetController {

    constructor(externalWidgetInstance) {
        super(externalWidgetInstance);
        this._serviceHost = externalWidgetInstance.getAttribute('data-lumino-service-host');
        this._partner = externalWidgetInstance.getAttribute('data-lumino-partner-name');
        this._pageName = externalWidgetInstance.getAttribute('data-lumino-page-name');
        this._redirectHost = externalWidgetInstance.getAttribute('data-lumino-redirect-host');
        this._searchModes = this.getDefaultSearchModes();
    }

    fetchInitializationDataAndInit() {
        const apiPath = `${this.getProtocol(this._serviceHost)}${this._serviceHost}/healthcommunity/pub/req/providersearch/util/formInfo?source=lumino&locale=${this._languageCode}_CA`;
        this.fetchInitializationDataFromAPI(apiPath);
    }

    initWidgetForm(data) {
        this._form = new ExternalPSWidgetForm(this._languageCode, this, data.suggestions, this._defaultSpecialty, this._defaultLocation, this._defaultCondition, this._defaultService,
            this._googleAPIKey, this._verticalOrientation, this._showHeaderText, this._uniqueId, this._searchModes);
        this._form.bindToDOM(this._widgetInstance);
    }

    initWidgetWithData(data) {


        // check to make sure widget isn't already initialized
        if(this._widgetInstance.childElementCount > 0 && this._widgetInstance.firstElementChild.localName === "form"){
            return;
        }

        super.initWidgetWithData(data);
        this.initWidgetForm(data);

    }

    getProtocol(host) {
        let protocol = "https://";
        if(!host) {
            return protocol;
        }

        if(super.isLocalhost(host)) {
            protocol = "http://";
        }

        return protocol;
    }


    isValidWidgetParameters() {
        // Potential of adding more validation here
        return this.validateRedirectHost();
    }

    validateRedirectHost() {

        if(super.isLocalhost(this._redirectHost)) {
            return true;
        }

        // Note: redirectHost must end with either ".sunlife", ".sunlife.ca" or ".sunlife.com"
        const regex = /(\.)(sunlife|sunlife\.ca|sunlife\.com)$/;
        if(this._redirectHost.match(regex, "gi")) {
            return true;
        } else {
            console.error("Error validating 'data-lumino-redirect-host' parameter. Please make sure you're using a valid host.");
            return false;
        }
    }

    getPartner() {
        return this._partner;
    }

    fetchInitializationDataFromAPI(apiPath) {
        super.fetchInitializationDataFromAPI(apiPath);
    }

    getSearchHost() {
        return this.getProtocol(this._redirectHost) + this._redirectHost;
    }

    createLuminoURL(suggestionToUse) {

        let redirectURL = super.createLuminoURL(suggestionToUse);

        redirectURL +=
            `&WT.mc_id=${this.getLanguageCode()}-ca:direct:redirect:extps:${this._partner}:${this._pageName}` +
            `&partner=${encodeURIComponent(this._partner)}`;

        return redirectURL;
    }

    executeSearch(attempt) {
        const luminoURL = this.createLuminoURL(attempt.getSuggestionToUse());
        window.open(luminoURL, "_blank");
    }
}
