'use strict';

import {Constants} from "../../constants";
import {LocationSuggestionsViewModelBuilder} from "./location-suggestions-view-model-builder";
import {PSWidgetCombobox} from "../combobox";
import {PSWidgetLocationField} from "./location-field";
import {PSWidgetLocationSuggestionsDropdown} from "./location-suggestions-dropdown";
import {Utils} from "../../utils";
import {PSWidgetGeolocationDisabledModal} from "../../geolocation-disabled-modal";
import { Loader } from '@googlemaps/js-api-loader';


export class PSWidgetLocationCombobox extends PSWidgetCombobox {

    #locationComboboxElement;
    #locationField;
    #locationSuggestionsDropdown;

    #locationOptions;
    #locationSuggestionsViewModel;
    #selectedSuggestion = null;

    #geolocatedAddress = null;
    #geolocationBlockedPopup;
    #addressChangedSinceLastGeolocationClick = false;
    #searchPerformed = false;

    constructor(language, uniqueId, locationOptions) {
        super(language, uniqueId);

        this.#locationOptions = locationOptions;

        this._currentPlace = null;
        this._autoCompleteService = null;
        this._placesService = null;
        this._autoCompleteSessionToken = null;

        this._defaultLocation = locationOptions.defaultLocation;
    }

    bindToDOM(parentDOMElement) {
        super.bindToDOM(parentDOMElement);

        this.#locationComboboxElement = this._domElement;

        this.#locationField = new PSWidgetLocationField(this._language, this._uniqueId, this.#locationOptions);
        this.#locationField.bindToDOM(this.#locationComboboxElement);

        this.setDefaultLocation(this._defaultLocation);
        this.loadGoogleMapsScript();
        this.bindEventListeners();
    }

    bindEventListeners() {
        super.bindEventListeners();

        this.getDOMElement().addEventListener('textInputTextChanged', () => {
            this._currentPlace = null;
            this.#searchPerformed = false;
            this.getPlacePredictions();

            this.triggerLocationWasManuallyChanged();
        });

        this.getDOMElement().addEventListener('clearClicked', () => {
            this.triggerLocationWasManuallyChanged();
            this.closeSuggestions(false, false);
            this.showSuggestions();
        });

        if(this.#locationOptions.enableGeolocation) {
            this.getDOMElement().addEventListener("geolocationClick", () => {
                this.onGeolocationClick();
            });
        }

        this.bindResizeEvent();
    }

    setDefaultLocation(defaultLocation) {
        if (!defaultLocation) {
            return;
        }

        this.setAddress(defaultLocation);
    }

    onClearButtonClick() {
        super.onClearButtonClick();

        this.triggerLocationWasManuallyChanged();
    }

    getGeolocationBlockedPopup() {
        return this.#geolocationBlockedPopup;
    }

    renderGeolocationDisabledModal() {
        if(this.#geolocationBlockedPopup) {
            return;
        }

        this.#geolocationBlockedPopup = new PSWidgetGeolocationDisabledModal(this._language, this.getInputField().getTextboxElement());
        this.#geolocationBlockedPopup.bindToDOM(document.body);

        this.#geolocationBlockedPopup.getDOMElement().addEventListener("providerSearchModalCloseRequested", () => {
            this.#geolocationBlockedPopup = null;
        })
    }

    setGeolocationAddress (address) {
        this.#geolocatedAddress = address;
    }

    getGeolocationAddress() {
        return this.#geolocatedAddress;
    }

    triggerLocationWasManuallyChanged() {
        this.#addressChangedSinceLastGeolocationClick = false;
    }

    resetSelectedSuggestion() {
        this.#selectedSuggestion = null;
        this._currentPlace = null;
        this.clearGoogleSuggestions();
    }

    getInputField() {
        return this.#locationField;
    }

    getSuggestionsDropdown() {
        return this.#locationSuggestionsDropdown;
    }

    setSuggestionsDropdown(value) {
        this.#locationSuggestionsDropdown = value;
    }

    getCurrentPlace() {
        return this._currentPlace;
    }

    getLocationValue() {
        return this.#locationField.getTextFieldValue();
    }

    removeErrorBox() {
        return this.#locationField.removeErrorBox();
    }

    getErrorMessage() {
        return this.#locationField.getErrorMessage();
    }

    isValidInput(dontShowErrors) {
        return this.#locationField.isValidInput(dontShowErrors);
    }

    setSearchPerformed(value) {
        this.#searchPerformed = value;
    }

    enterKeyWasPressedOnSuggestion() {
        if(!this.getSuggestionsDropdown()?.hasHighlightedSuggestion()) {
            return;
        }

        const highlightedSuggestion = this._domElement.querySelector(".highlighted-suggestion");
        if (!highlightedSuggestion) {
            return;
        }

        if(!!this.#locationOptions.enableGeolocation && highlightedSuggestion === this.getSuggestionsDropdown().getGeolocationButton().getDOMElement()) {
            this.getSuggestionsDropdown().getGeolocationButton().onGeolocationClick();
            return;
        }

        const locationText = highlightedSuggestion.getAttribute("data-lumino-location-suggestion-text");
        const placeId = highlightedSuggestion.getAttribute("data-lumino-location-suggestion-placeid");
        this.locationSuggestionWasPressed(placeId, locationText);
    }

    onLocationFieldUserInput() {
        this.#selectedSuggestion = null;
        this.closeSuggestions(false, false);
        this.showSuggestions();
    }

    onTextFieldComesIntoFocus() {
        this.#searchPerformed = false;

        if(this.getSuggestionsDropdown()) {
            return;
        }

        if(this.#selectedSuggestion && !this.#locationOptions.enableGeolocation) {
            return;
        }

        this.adjustForMobileLayoutOnFocus();
        this.showSuggestions();
    }

    showSuggestions() {
        const showGeolocation = this.#locationOptions.enableGeolocation;
        this.#locationSuggestionsDropdown = new PSWidgetLocationSuggestionsDropdown(this._language, this._uniqueId, this.#locationSuggestionsViewModel, showGeolocation);
        this.#locationSuggestionsDropdown.bindToDOM(this.#locationComboboxElement);

        this._domElement.parentElement.classList.add("opened");

        this.#locationSuggestionsDropdown.getDOMElement().addEventListener("closeLocationSuggestion", () => {
            this.closeSuggestions(false, true);
        });

        this.#locationSuggestionsDropdown.getDOMElement().addEventListener("suggestionClicked", (e) => {
            e.preventDefault();
            this.locationSuggestionWasPressed(e.detail.placeId, e.detail.text);
        });

        this.getInputField().updateAttribute("aria-expanded", "true");
        this.updateSuggestionsWrapperScreenPosition();
    }

    onGeolocationClick() {
        this.closeSuggestions(false, true);

        // Prevents user from making additional Google API calls if address in location box is same as geolocation address
        if (this.#addressChangedSinceLastGeolocationClick) {
            return;
        }

        this.getLatLongFromBrowserGeolocationAndPopulateLocationInput();
    }

    getLatLongFromBrowserGeolocationAndPopulateLocationInput() {
        // Try to get the geolocation from browser
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {

                this.onGeolocationSuccess(position.coords.latitude, position.coords.longitude);

            }, () => {
                // Geolocation failed (user blocked geolocation)
                this.onGeolocationFailed();
            });
        } else {
            // Browser doesn't support Geolocation
            this.onGeolocationFailed();
        }
    }

    getLatLngObject(latitude, longitude) {
        return new google.maps.LatLng(latitude, longitude);
    }

    onGeolocationFailed() {
        this.renderGeolocationDisabledModal();
    }

    onGeolocationSuccess(latitude, longitude) {
        // Save location information in Google map LanLng object
        const latLng = this.getLatLngObject(latitude, longitude);

        // Convert coordinates to a formatted address
        this.populateLocationInputFromLatLng(latLng);
    }

    getGeocoder() {
        return new google.maps.Geocoder();
    }

    populateLocationInputFromLatLng(latLng) {
        // Initilize Google Geo-coder
        const geocoder = this.getGeocoder();

        // Call Google API to return an address component using coordinates
        geocoder.geocode({
            latLng: latLng
        }, (results, status) => {
            this.onGeocodeCallback(results, status, this);
        });
    }

    checkGeoCodeStatus(status) {
        return status === google.maps.GeocoderStatus.OK;
    }

    onGeocodeCallback(results, status, thisClass) {
        if (!thisClass.checkGeoCodeStatus(status)) {
            // failed
            return;
        }

        const place = results[0];

        thisClass.triggerGeolocationPlaceChanged(place);

        // Used on MbrPortal to log eLogs and n PS to track the most recent geolocation address (for AA and MUM events)
        thisClass.getDOMElement().dispatchEvent(new CustomEvent('geolocationClicked', {bubbles: true, detail: Utils.removeUnitNumberInAddress(place.formatted_address) }));
    }

    triggerGeolocationPlaceChanged(place) {
        const address = Utils.removeUnitNumberInAddress(place.formatted_address);

        this.setAddress(address);
        this.setGeolocationAddress(address);
        this.#addressChangedSinceLastGeolocationClick = true;

        this.clearGoogleSuggestions();

        this._currentPlace = place;
        this.#locationField.removeErrorBox();
    }

    loadGoogleMapsScript() {

        if(!this.#locationOptions.googleAPIKey) {
            // Assume there's already a Google Maps Places library on page
            this.initGoogleAutocompleteService();
            return;
        }

        if(!(typeof google === 'object' && typeof google.maps === 'object')) {
            // If we find NO Google Maps object, we try to load it (but only if we have an API key)
            if(!this.#locationOptions.googleAPIKey) {
                console.warn("Please provide a valid Google API Key or load Google Maps API manually to enable location autocomplete.");
                return;
            }

            // If Google Maps is not loaded on the page already, load it dynamically
            const googleApiLoader = this.buildGoogleAPILoader();
            googleApiLoader.importLibrary('maps').then(async () => {
                // Successfully loaded Google Maps, so initialize the autocomplete
                this.initGoogleAutocompleteService();
            });
        } else {

            // User provided an API key and we found Google Maps on page, so just initialize the autocomplete
            this.initGoogleAutocompleteService();
        }
    }

    buildGoogleAPILanguage() {
        if(this._language.toLowerCase() === Constants.LANG_FR) {
            return "fr-CA";
        }

        return this._language;
    }

    buildGoogleAPILoader() {
        return new Loader({
            apiKey: this.#locationOptions.googleAPIKey,
            version: Constants.GOOGLE_API_VERSION,
            libraries: ["places"],
            language: this.buildGoogleAPILanguage(),
            region: "CA"
        });
    }

    initGoogleAutocompleteService() {

        let attempts = 20;
        const autocompleteLoaderInterval = setInterval(() => {
            attempts--;

            // Be sure the google.maps.places object exists before attempting to initialize autocomplete
            if(typeof google === 'object' && typeof google.maps === 'object' && typeof google.maps.places === 'object') {

                this._autoCompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
                this._autoCompleteService = new google.maps.places.AutocompleteService();
                this._placesService = new google.maps.places.PlacesService(document.querySelector('[data-lumino-widget-id="google-places-service-attributions"]'));
                clearInterval(autocompleteLoaderInterval);
            }

            if(attempts === 0) {
                clearInterval(autocompleteLoaderInterval);
            }
        }, 500);
    }

    displaySuggestions(predictions, status) {
        if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
            console.log("Error getting predictions: " + status);
            return;
        }

        if(this.#searchPerformed) {
            return;
        }

        const locationSuggestionsBuilder = new LocationSuggestionsViewModelBuilder();
        this.#locationSuggestionsViewModel = locationSuggestionsBuilder.buildLocationSuggestionsViewModel(predictions);

        this.onLocationFieldUserInput();
    }

    getPlacePredictions() {
        if(this._autoCompleteService) {
            const request = {
                input: this.#locationField.getTextFieldValue(),
                sessionToken: this._autoCompleteSessionToken,
                componentRestrictions: {country: 'ca'}
            };

            try {
                this._autoCompleteService.getPlacePredictions(request, this.displaySuggestions.bind(this));
            } catch (err) {
                console.log(err);
            }
        }
    }

    locationSuggestionWasPressed(placeId, location) {
        this.setAddress(location);
        this.getPlacesDetails(placeId);
    }

    getPlacesDetails(placeId) {
        const placeDetailsRequest = {
            placeId: placeId,
            sessionToken: this._autoCompleteSessionToken,
            fields: ["formatted_address", "geometry", "address_components"]
        };
        this._placesService.getDetails(placeDetailsRequest, this.handlePlaceDetailsSuccess.bind(this));
    }

    handlePlaceDetailsSuccess(placeResult, status) {
        if (status !== google.maps.places.PlacesServiceStatus.OK || !placeResult) {
            console.log("Error getting place details: " + status);
            return;
        }

        this._currentPlace = placeResult;

        this.closeSuggestions(true, true);
        this.clearGoogleSuggestions();
    }

    clearGoogleSuggestions() {
        this.#locationSuggestionsViewModel = null;
    }

    setAddress(address) {
        if (!address) {
            address = "";
        }

        this.#locationField.setTextFieldValue(address);
        this.#selectedSuggestion = address;
    }

    render() {
        return `<div data-lumino-widget-id="lumino-widget-location-combobox" class="lumino-widget-location-combobox"></div>`;
    }
}
