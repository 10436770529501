'use strict';

import {Constants} from "./constants";

export class PSWidgetLocalizedLabels {
    #language;
    #localizedLabels;

    #enLabels;
    #frLabels;

    constructor(language) {
        this.#language = language;

        this.#localizedLabels = new Map();
        this.#enLabels = new Map();
        this.#frLabels = new Map();

        this.#localizedLabels.set(Constants.LANG_EN, this.#enLabels);
        this.#localizedLabels.set(Constants.LANG_FR, this.#frLabels);
    }

    addLocalizedLabel(key, enLabel, frLabel) {
        this.#localizedLabels.get(Constants.LANG_EN).set(key, enLabel);
        this.#localizedLabels.get(Constants.LANG_FR).set(key, frLabel);
    }

    getLocalizedLabel(key) {
        return this.#localizedLabels.get(this.#language).get(key);
    }
}
