'use strict';

import {PSWidgetTextField} from "../text-field";
import {PSWidgetClearFieldButton} from "../../clear-field-button";

export class PSWidgetLocationField extends PSWidgetTextField {
    #fieldIdentifier = "widgetLocationField";
    #locationBackButtonElement;

    constructor(language, uniqueId, locationOptions) {
        super(language, uniqueId);

        this._locationPlaceholderText = locationOptions.locationPlaceholderText;
        this._locationLabelText = locationOptions.locationLabelText;

        this._showLocationIcon = locationOptions.showLocationIcon;
    }

    bindToDOM(parentDOMElement) {
        super.bindToDOM(parentDOMElement);

        this._textboxElement = this._domElement.querySelector('[data-lumino-widget-id="lumino-widget-location-textfield"]');
        this._textboxWrapper = this._domElement.querySelector('[data-lumino-widget-id="lumino-widget-location-field-wrapper"]');
        this.#locationBackButtonElement = this._domElement.querySelector('[data-lumino-widget-id="lumino-widget-textbox-back-btn"]');

        this._clearFieldComponent = new PSWidgetClearFieldButton(this._language, this.getLocalizedLabel("clearAriaLabel"));
        this._clearFieldComponent.bindToDOM(this._textboxWrapper);

        this.bindEventListeners();
    }

    getTextboxBackButton() {
        return this.#locationBackButtonElement;
    }

    getClearFieldButton() {
        return this._clearFieldComponent;
    }

    inputValidation(dontShowErrors) {
        if(this.isEmptyField()) {
            if(!dontShowErrors) {
                this.renderErrorBox(this.getLocalizedLabel("validation_requiredField"));
            }

            return false;
        }

        return true;
    }

    buildLocationPlaceholderText() {
        return this._locationPlaceholderText ? this._locationPlaceholderText : this.getLocalizedLabel("location_placeholder");
    }

    buildLocationLabelText() {
        return this._locationLabelText ? this._locationLabelText : this.getLocalizedLabel("location_label");
    }

    renderLocationIcon() {
        if(!this._showLocationIcon) {
            return ``;
        }

        return `<div class="lumino-widget-location-icon-wrapper" data-lumino-widget-id="lumino-widget-location-icon-wrapper">${ this.getLocationSVGString() }</div>`;
    }

    getLabelElement() {
        return this._domElement.querySelector('[data-lumino-widget-id="lumino-widget-location-label"]');
    }

    getFieldIdentifier() {
        return this.#fieldIdentifier;
    }

    render() {
        return `
        <div data-lumino-widget-id="lumino-widget-location-textfield-wrapper" class="lumino-widget-location-textfield-wrapper">
            <label data-lumino-widget-id="lumino-widget-location-label" class="lumino-widget-location-label" for="lumino-widget-location-textfield-${ this._uniqueId }">${ this.buildLocationLabelText() }</label>
            <span class="prvsrch-sr-only">${this.getLocalizedLabel("srTextHintForLocationSuggestions")}</span>
            
            <div data-lumino-widget-id="lumino-widget-location-field-wrapper" class="lumino-widget-location-field-wrapper">
                ${this.renderLocationIcon()}
                ${ this.renderTextboxBackButton() }
                <input type="text" role="combobox" aria-controls="lumino-location-suggestions-wrapper-${this._uniqueId}" aria-autocomplete="list" data-lumino-widget-id="lumino-widget-location-textfield" class="lumino-widget-location-textfield" id="lumino-widget-location-textfield-${ this._uniqueId }" placeholder="${ this.buildLocationPlaceholderText() }" required="" aria-expanded="false">
            </div>
            <div class="google-places-service-attributions" data-lumino-widget-id="google-places-service-attributions"></div>
        </div>`;
    }

    addLocalizedLabels() {
        this.addLocalizedLabel("location_label", "Location", "Emplacement");
        this.addLocalizedLabel("location_placeholder", "Address, city, postal code", "Adresse, ville, code postal");
        this.addLocalizedLabel("clearAriaLabel", "Clear location field", "Effacer le champ de l&#39;emplacement");
        this.addLocalizedLabel("backAriaLabel","Back","Retour");

        this.addLocalizedLabel("srTextHintForLocationSuggestions", "Suggestions display as you type. Use up and down arrow keys to select an option, and enter to use it.", "Les suggestions s\u2019affichent \u00e0 mesure que vous tapez. Appuyez sur les touches vers le haut et vers le bas pour s\u00e9lectionner une option, puis sur la touche Entr\u00e9e pour utiliser l\u2019option choisie.");
        this.addLocalizedLabel("validation_requiredField","Location field is required","Le champ Emplacement est obligatoire");
    }

    getLocationSVGString() {
        return `<svg aria-hidden="true" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 9 13"><defs><clipPath ><path class="cls-1" d="M-352-446.09a2.32,2.32,0,0,1-2.26-2.37,2.32,2.32,0,0,1,2.26-2.37,2.32,2.32,0,0,1,2.27,2.37A2.32,2.32,0,0,1-352-446.09Zm0-7.08a4.62,4.62,0,0,0-4.5,4.71c0,3.22,4,7.95,4.2,8.15a.39.39,0,0,0,.6,0c.18-.2,4.2-4.93,4.2-8.15A4.62,4.62,0,0,0-352-453.17Z"/></clipPath></defs><g ><g ><g ><g ><g ><g ><g ><path  class="cls-3" d="M4.5,0A4.61,4.61,0,0,0,0,4.71c0,3.22,4,8,4.2,8.15a.39.39,0,0,0,.6,0C5,12.66,9,7.93,9,4.71A4.61,4.61,0,0,0,4.5,0Zm0,7.08A2.32,2.32,0,0,1,2.24,4.71,2.32,2.32,0,0,1,4.5,2.34,2.32,2.32,0,0,1,6.76,4.71,2.32,2.32,0,0,1,4.5,7.08Z"/></g></g></g></g></g></g></g></svg>`;
    }
}