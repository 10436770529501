'use strict';

import {PSWidgetBasicComponent} from "./basic-component";
import {PSWidgetSearchButton} from "./search-button";
import {Utils} from "./utils";
import {Constants} from "./constants";
import {ProviderSearchAttempt} from "./search-attempt";
import {PSWidgetFindCombobox} from "./suggestions/find/find-combobox";

export class AbstractPSWidgetForm extends PSWidgetBasicComponent {
    #formSubmitTimer;
    #formSubmitReady = true;
    #inputValidationSRElement;
    #suggestionsData;
    #findFieldPlaceholderText;
    #findFieldLabelText;
    #useConfigSpecialtySorting;

    #keywordLoggingEveryNCharacters;
    #keywordLoggingBackspaceLogged;
    
    #findCombobox;

    constructor(language, luminoWidgetController, defaultSpecialty, defaultLocation, defaultCondition, defaultService, googleAPIKey, verticalOrientationAttr,
                showHeaderText = true, uniqueId, searchModes, findFieldPlaceholderText, findFieldLabelText, useConfigSpecialtySorting, keywordLogging, suggestionsData) {
        super(language);

        this._controller = luminoWidgetController;
        this._defaultSpecialty = defaultSpecialty;
        this._defaultLocation = defaultLocation;
        this._defaultCondition = defaultCondition;
        this._defaultService = defaultService;
        this._googleAPIKey = googleAPIKey;
        this._verticalOrientation = verticalOrientationAttr;
        this._showHeaderText = showHeaderText;
        this._uniqueId = uniqueId;
        this._searchModes = searchModes;

        this.#suggestionsData = suggestionsData;
        this.#useConfigSpecialtySorting = useConfigSpecialtySorting;

        this.#findFieldPlaceholderText = findFieldPlaceholderText;
        this.#findFieldLabelText = findFieldLabelText;
        window.fuzzyMatchScore = Constants.FUZZY_MATCH_SCORE;

        if(keywordLogging) {
            this.#keywordLoggingEveryNCharacters = keywordLogging.keywordLoggingEveryNCharacters;
        }

        this.#keywordLoggingBackspaceLogged = false;
    }

    getController() {
        return this._controller;
    }

    bindToDOM(parentDOMElement) {
        super.bindToDOM(parentDOMElement);

        // Add 'data-lumino-widget-initialized' to outer HTML element letting app know it was already loaded
        parentDOMElement.setAttribute("data-lumino-widget-initialized", "");

        this.#findCombobox = new PSWidgetFindCombobox(this._language, this._uniqueId, this.#findFieldPlaceholderText, this.#findFieldLabelText, this.getFeatureToggleOptions(), this.#suggestionsData, this.#useConfigSpecialtySorting);
        this.#findCombobox.bindToDOM(this.getFindComboboxWrapper());

        this._searchButtonElement = new PSWidgetSearchButton(this._language);
        this._searchButtonElement.bindToDOM(this._domElement.querySelector('[data-lumino-widget-id="lumino-widget-search-button-wrapper"]'));

        this.#inputValidationSRElement = this._domElement.querySelector('[data-lumino-widget-id="lumino-widget-form-validation-sr"]');

        window.addEventListener('beforeunload', () => {
            this.getController().sendSearchKeywordEvent(Constants.KEYWORD_EVENT_TRIGGER_LEAVE_PAGE, this.#findCombobox.getSelectedSuggestion(), this.#findCombobox.getExactMatchCurrentlyUsed());
        });

        this._domElement.addEventListener('executeSearch', () => {
            this.searchButtonClicked();
        });

        if(this._defaultSpecialty) {
            this.#findCombobox.selectSuggestion(Constants.SUGGESTION_TYPE_SPECIALTY, Constants.SUGGESTION_MATCH_TYPE_LABEL, Constants.SUGGESTION_SECTION_SUGGESTED_SPECIALTIES, this._defaultSpecialty);
        }

        if(this._defaultCondition) {
            this.#findCombobox.selectSuggestion(Constants.SUGGESTION_TYPE_CONDITION, Constants.SUGGESTION_MATCH_TYPE_LABEL, Constants.SUGGESTION_SECTION_SUGGESTED_CONDITIONS, this._defaultCondition);
        }

        if(this._defaultService) {
            this.#findCombobox.selectSuggestion(Constants.SUGGESTION_TYPE_SERVICE, Constants.SUGGESTION_MATCH_TYPE_LABEL, Constants.SUGGESTION_SECTION_SUGGESTED_SERVICES, this._defaultService);
        }

        this.#findCombobox.getDOMElement().addEventListener("textInputTextChanged", (e) => {
            this.logMUMKeywordOnInput();

            if (e.detail.inputType === "insertText") {
                this.#keywordLoggingBackspaceLogged = false;
            }
        });

        this.#findCombobox.getDOMElement().addEventListener("textInputTextRemoved", () => {
            this.logKeywordOnDeleteAndBackspace();
        });

        this.#findCombobox.getDOMElement().addEventListener("findSuggestionClicked", (e) => {
            const suggestion = e.detail.suggestion;

            // this supports the navigation events logged when a user selects a specialty
            if(suggestion.getType() === Constants.SUGGESTION_TYPE_SPECIALTY) {
                this._controller.logNavigationEvent("select/" + suggestion.getValue());
            }

            this.getController().sendSearchKeywordEvent(Constants.KEYWORD_EVENT_SUGGESTION_SELECTED, suggestion, false);
        });
    }

    logKeywordOnDeleteAndBackspace() {
        if(this.#keywordLoggingBackspaceLogged) {
            return;
        }

        this.getController().sendSearchKeywordEvent(Constants.KEYWORD_EVENT_TRIGGER_DELETE_CHARACTERS, null, false);
        this.#keywordLoggingBackspaceLogged = true;
    }

    getFindCombobox() {
        return this.#findCombobox;
    }

    getLocationCombobox() {
        return this._locationCombobox;
    }

    getSearchButton() {
        return this._searchButtonElement;
    }

    searchButtonClicked() {
        this.executeSearchIfPossible();
    }

    setSRValidationContent(content) {
        if(!content) {
            this.#inputValidationSRElement.innerHTML = "";
            return;
        }

        this.#inputValidationSRElement.innerHTML = content;
    }

    updateSRValidationContent() {
        if(this.getFindCombobox().getErrorMessage()) {
            this.setSRValidationContent(this.getFindCombobox().getErrorMessage());

        } else if(this.getLocationCombobox().getErrorMessage()) {
            this.setSRValidationContent(this.getLocationCombobox().getErrorMessage());
        }
    }

    validateFormAndGetSearchAttempt(dontShowErrors, suggestionSection) {

        const attempt = new ProviderSearchAttempt();

        attempt.setLocation(this.getLocationValue());
        attempt.setSearchedText(this.getSearchedText());

        // if location isn't valid, can't search, return null
        if(!this.getLocationCombobox().isValidInput(dontShowErrors)) {
            attempt.getErrorCodes().push(Constants.SEARCH_ERROR_CODE_MISSING_LOCATION);
            attempt.setLocationIsValid(false);
        } else {
            attempt.setLocationIsValid(true);
        }

        let suggestionToUse = null;

        // if search field text is invalid, can't search
        if(this.getFindCombobox().isValidInput(dontShowErrors)) {

            if (this.#findCombobox.getSelectedSuggestion()) {
                suggestionToUse = this.#findCombobox.getSelectedSuggestion();
            } else {
                // check if there is only one valid suggestion
                if (this.#findCombobox.getLastSuggestionsViewModel()) {
                    suggestionToUse = this.#findCombobox.getExactMatchSuggestionToUseForSearch(attempt);
                }
            }

            if (!suggestionToUse) {
                this.getFindCombobox().renderErrorBox(this.getLocalizedLabel("validation_noSelectionMade"));
                attempt.getErrorCodes().push(Constants.SEARCH_ERROR_CODE_SUGGESTION_NOT_SELECTED);
            }
        } else {
            attempt.getErrorCodes().push(Constants.SEARCH_ERROR_CODE_INVALID_SEARCH_TERM);
        }

        if(suggestionSection) {
            attempt.setExactMatch(suggestionSection === Constants.SUGGESTION_SECTION_EXACT_MATCH);
            if(suggestionToUse) {
                suggestionToUse.setSection(suggestionSection);
                attempt.setSuggestionToUse(suggestionToUse);
            }
        } else {
            attempt.setExactMatch(this.#findCombobox.getExactMatchCurrentlyUsed());
            attempt.setSuggestionToUse(suggestionToUse);
        }

        if(attempt.getErrorCodes().length > 0) {
            this.updateSRValidationContent();
        } else {
            this.setSRValidationContent("");
        }

        return attempt;
    }

    getLastSuggestionsViewModel() {
        return this.#findCombobox.getLastSuggestionsViewModel();
    }

    executeSearchIfPossible(dontShowErrors, dontLogAA, dontLogMUM) {
        const attempt = this.validateFormAndGetSearchAttempt(dontShowErrors);
        if(attempt.getSuggestionToUse()) {
            this.#findCombobox.setSelectedSuggestion(attempt.getSuggestionToUse());
        }

        this.#findCombobox.closeSuggestions(false, true);
        this._locationCombobox.closeSuggestions(false, true);

        if(!dontLogAA) {
            this.getController().triggerSearchClickAnalytics(attempt);
        }

        if(!dontLogMUM) {
            this.getController().logNavigationEvent('providersearch/dosearch');
            this.getController().sendSearchKeywordEvent(Constants.KEYWORD_EVENT_TRIGGER_SEARCH_EXECUTED, attempt.getSuggestionToUse(), attempt.isExactMatch());
        }

        if(!attempt.getSuggestionToUse() || !attempt.getLocation()) {
            if(!dontLogMUM) {
                this.getController().sendSearchErrorEvent(attempt);
            }
            return false;
        }

        // Prevent form from being submitted by holding Enter/Space key
        if(this.#formSubmitReady) {
            this._locationCombobox.setSearchPerformed(true);
            this._controller.executeSearch(attempt);

            this.#formSubmitReady = false;
            this.#keywordLoggingBackspaceLogged = false;
        }

        clearTimeout(this.#formSubmitTimer);
        this.#formSubmitTimer = setTimeout(() => {
            this.#formSubmitReady = true;
        }, 1000);

        return true;
    }

    logMUMKeywordOnInput() {
        if(!this.getController().hasMumEnabled()) {
            return;
        }

        const textBoxValue = this.#findCombobox.getSearchedText();
        if(textBoxValue.length < Constants.SUGGESTION_MIN_INPUT_LENGTH) {
            return;
        }

        if(textBoxValue.length === Constants.SUGGESTION_MIN_INPUT_LENGTH) {
            this.getController().sendSearchKeywordEvent(Constants.KEYWORD_EVENT_TRIGGER_MINIMUM_CHARACTERS, null, false);
        }

        this.logEveryNCharKeywordEvent(textBoxValue);
        this.logKeywordOnPause();
    }

    logEveryNCharKeywordEvent(textBoxValue) {
        // We are checking for numbers that have a remainder of '0' when divided by 'keywordLoggingEveryNCharacters'.
        // Ex: textBoxValue.length == 4, then the remainder when dividing by 3 is 1, therefore return.
        // If textBoxValue.length == 3, 6, 9, etc...the remainder when dividing by 3 is 0, therefore log the event.
        if(textBoxValue.length % this.#keywordLoggingEveryNCharacters !== 0) {
            return;
        }

        this.getController().sendSearchKeywordEvent(Constants.KEYWORD_EVENT_TRIGGER_EVERY_N_CHARACTERS, null, false);
    }

    logKeywordOnPause = Utils.debounce(
        () => this.getController().sendSearchKeywordEvent(Constants.KEYWORD_EVENT_TRIGGER_PAUSE, null, false),
        window.PSLauncherKeywordLoggingPause
    );

    getFeatureToggleOptions() {
        return {
            nameSearchEnabled: this.isNameSearchEnabled(),
            specialtySearchEnabled: this.isSpecialtySearchEnabled(),
            conditionSearchEnabled: this.isConditionSearchEnabled(),
            serviceSearchEnabled: this.isServiceSearchEnabled()
        };
    }

    isSpecialtySearchEnabled() {
        return this._searchModes.indexOf(Constants.SEARCH_MODE_SPECIALTY) > -1;
    }

    isNameSearchEnabled() {
        return this._searchModes.indexOf(Constants.SEARCH_MODE_NAME) > -1;
    }

    isConditionSearchEnabled() {
        return this._searchModes.indexOf(Constants.SEARCH_MODE_CONDITION) > -1;
    }

    isServiceSearchEnabled() {
        return this._searchModes.indexOf(Constants.SEARCH_MODE_SERVICE) > -1;
    }

    isFavouritesEnabled() {
        return this._searchModes.indexOf(Constants.SEARCH_MODE_FAVOURITES) > -1;
    }

    isAppointmentsEnabled() {
        return this._showMyAppointments;
    }

    bindLocationFieldToDOM() {
        this._locationCombobox.bindToDOM(this.getLocationComboboxWrapper());
    }

    getSuggestionsData() {
        return this.#suggestionsData;
    }

    getDefaultLocation() {
        return this._defaultLocation;
    }

    resetToEmptyValues() {
        this.setLocationValue("");
        this.setSearchedText("");
    }

    getLocationValue() {
        return this._locationCombobox.getLocationValue();
    }

    getLocationPlace() {
        return this._locationCombobox.getCurrentPlace();
    }

    setLocationValue(location) {
        return this._locationCombobox.setAddress(location);
    }

    setGeolocationAddress(location) {
        return this._locationCombobox.setGeolocationAddress(location);
    }

    getGeolocationAddress() {
        return this._locationCombobox.getGeolocationAddress();
    }

    removeSearchAndLocationErrorBox() {
        // Used on static-docs
        this.getFindCombobox().removeErrorBox();
        this.getLocationCombobox().removeErrorBox();
    }

    getSearchedText() {
        return this.#findCombobox.getSearchedText();
    }

    selectSuggestionForMode(searchMode, matchType, code, sectionType, labelForTextBox) {

        let suggestionType = null;

        if(searchMode === Constants.SEARCH_MODE_SPECIALTY) {

            suggestionType = Constants.SUGGESTION_TYPE_SPECIALTY;
            if(!sectionType) {
                sectionType = Constants.SUGGESTION_SECTION_SUGGESTED_SPECIALTIES;
            }
        } else if(searchMode === Constants.SEARCH_MODE_NAME) {

            suggestionType = Constants.SUGGESTION_TYPE_NAME;
            if(!sectionType) {
                sectionType = Constants.SUGGESTION_SECTION_SUGGESTED_NAME;
            }
            labelForTextBox = code;
        } else if(searchMode === Constants.SEARCH_MODE_CONDITION) {

            suggestionType = Constants.SUGGESTION_TYPE_CONDITION;
            if(!sectionType) {
                sectionType = Constants.SUGGESTION_SECTION_SUGGESTED_CONDITIONS;
            }
        } else if(searchMode === Constants.SEARCH_MODE_SERVICE) {

            suggestionType = Constants.SUGGESTION_TYPE_SERVICE;
            if(!sectionType) {
                sectionType = Constants.SUGGESTION_SECTION_SUGGESTED_SERVICES;
            }
        }

        this.#findCombobox.selectSuggestion(suggestionType, matchType, sectionType, code, labelForTextBox);
    }


    setSearchedText(value) {
        // Also called by static-docs

        if(this.isFavouritesEnabled() && value === "") {
            this.#findCombobox.resetSelectedSuggestion();
            this.#findCombobox.setSearchedText(value);
            return;
        }

        if(!this.isNameSearchEnabled()) {
            return;
        }

        this.#findCombobox.selectSuggestion(Constants.SUGGESTION_TYPE_NAME, Constants.SUGGESTION_MATCH_TYPE_LABEL,
            Constants.SUGGESTION_SECTION_SUGGESTED_NAME, value);
    }

    setSearchedTextFromSpecialtyCode(specialtyCode) {
        // Used by static-docs
        this.#findCombobox.selectSuggestion(Constants.SUGGESTION_TYPE_SPECIALTY, Constants.SUGGESTION_MATCH_TYPE_LABEL, Constants.SUGGESTION_SECTION_SUGGESTED_SPECIALTIES, specialtyCode);
    }

    getFindComboboxWrapper() {
        return this._domElement.querySelector('[data-lumino-widget-id="lumino-widget-search-textbox-wrapper"]');
    }

    getLocationComboboxWrapper() {
        return this._domElement.querySelector('[data-lumino-widget-id="lumino-widget-location-field"]');
    }

    renderInputsAndSearchButton() {
        return `<div class="lumino-widget-input-fields-wrapper">
                    <div class="lumino-widget-search-textbox-wrapper lumino-widget-input-wrapper" data-lumino-widget-id="lumino-widget-search-textbox-wrapper"></div>
                    <div class="lumino-widget-location-field lumino-widget-input-wrapper" data-lumino-widget-id="lumino-widget-location-field"></div>
                    <div class="lumino-widget-search-button-wrapper" data-lumino-widget-id="lumino-widget-search-button-wrapper"></div>
                    <div class="prvsrch-sr-only" aria-live="polite" data-lumino-widget-id="lumino-widget-form-validation-sr"></div>
                </div>`;
    }

    renderHeader() {
        // this should be rendered in child classes!
        return '';
    }

    render() {
        // this should be rendered in child classes!
        return '';
    }

    addLocalizedLabels() {
        this.addLocalizedLabel("headerText", "Find a health-care provider that's right for you.", "Trouvez le professionnel de la sant&eacute; qui vous convient.");
        this.addLocalizedLabel("validation_noSelectionMade","Select an option to complete your search","Choisissez une option pour terminer votre recherche");
    }
}
