'use strict';

export class ProviderSearchAttempt {

    #suggestionToUse;
    #location;
    #errorCodes = [];
    #locationValid = false;
    #searchedText;
    #exactMatch = false;

    setSuggestionToUse(suggestionToUse) {
        this.#suggestionToUse = suggestionToUse;
    }

    getSuggestionToUse() {
        return this.#suggestionToUse;
    }

    setLocation(location) {
        this.#location = location;
    }

    getLocation() {
        return this.#location;
    }

    getErrorCodes() {
        return this.#errorCodes;
    }

    setLocationIsValid(value) {
        this.#locationValid = value;
    }

    getIsLocationValid() {
        return this.#locationValid;
    }

    setSearchedText(value) {
        this.#searchedText = value;
    }

    getSearchedText() {
        return this.#searchedText;
    }

    isExecutable() {
        return this.getSuggestionToUse() && this.getIsLocationValid();
    }

    isExactMatch() {
        return this.#exactMatch;
    }

    setExactMatch(value) {
        this.#exactMatch = value;
    }

}