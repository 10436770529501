'use strict';
import {PSWidgetBasicComponent} from "./basic-component";

export class PSWidgetClearFieldButton extends PSWidgetBasicComponent {

    constructor(language, ariaLabel) {
        super(language);

        this._ariaLabel = ariaLabel;
    }

    bindToDOM(parentDOMElement) {
        super.bindToDOM(parentDOMElement);

        this._domElement.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            this._domElement.dispatchEvent(new CustomEvent('clearClicked', {bubbles: true}));
        });
    }

    showClearButton() {
        this._domElement.classList.remove("hidden");
    }

    hideClearButton() {
        this._domElement.classList.add("hidden");
    }

    render() {
        return `<button type="button" class="hidden lumino-widget-clear-icon-wrapper" aria-label="${this._ariaLabel}" data-lumino-widget-id="lumino-widget-clear-icon-wrapper">${ this.getCloseSVGString() }</button>`;
    }

    getCloseSVGString() {
        return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.18 9.18" role="img"><title>${this._ariaLabel}</title><defs></defs><g><g><g ><g><g><g><path class="cls-1" d="M.76.76,8.41,8.41"/></g></g></g></g></g></g><g><g><g><g><g><g><path class="cls-1" d="M.76,8.41,8.41.76"/></g></g></g></g></g></g></svg>`;
    }
}
