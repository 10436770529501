'use strict';

import {PSWidgetLocalizedLabels} from "./localized-labels";

export class PSWidgetBasicComponent {
    #localizedLabels;

    constructor(language) {
        this._domElement = null;
        this._language = language;

        this.#localizedLabels = new PSWidgetLocalizedLabels(language);
        this.addLocalizedLabels();
    }

    prependToDOM(parentDOMElement) {
        if(this._domElement) {
            return;
        }

        parentDOMElement.insertAdjacentHTML('afterbegin', this.render());
        this._domElement = parentDOMElement.firstElementChild;

        return this._domElement;
    }

    bindToDOM(parentDOMElement) {
        if(this._domElement) {
            return;
        }

        parentDOMElement.insertAdjacentHTML('beforeend', this.render());
        this._domElement = parentDOMElement.lastElementChild;

        return this._domElement;
    }

    getDOMElement() {
        return this._domElement;
    }

    render() {
        throw "render function must be overridden in child class"
    }

    destroy() {
        if(this._domElement) {
            this._domElement.parentNode.removeChild(this._domElement);
            this._domElement = null;
        }
    }

    addLocalizedLabels() {
        // Override in child classes
    }

    addLocalizedLabel(key, enLabel, frLabel) {
        this.#localizedLabels.addLocalizedLabel(key, enLabel, frLabel);
    }

    getLocalizedLabel(key) {
        return this.#localizedLabels.getLocalizedLabel(key);
    }
}
