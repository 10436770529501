'use strict';


export class LocationSuggestionsViewModel {

    #locationSuggestions = [];

    getLocationSuggestions() {
        return this.#locationSuggestions;
    }

    setLocationSuggestions(value) {
        this.#locationSuggestions = value;
    }
}
