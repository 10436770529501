'use strict';

import {PSWidgetModal} from "./dhs-modal";

export class PSWidgetGeolocationDisabledModal extends PSWidgetModal {
    constructor(locale, triggeringElement) {
        const options = {
            triggeringElement: triggeringElement,
            cssWrapperClasses: "prvsrch-geolocation-disabled-modal",
            backdropClickClose: true,
            keyboardEscClose: true,
            modalSizeClass: "prvsrch-modal-large"
        };

        super(locale, options);
    }

    bindToDOM(parentDOMElement) {
        if(this._domElement) {
            return;
        }
        super.bindToDOM(parentDOMElement);

        return this._domElement;
    }

    renderBody() {
        return `<p>${ this.getLocalizedLabel("modalBody") }</p>`;
    }

    renderFooter() {
        return `<button type="button" data-prvsrchid="prvsrch-modal-close" class="btn btn-blue">${this.getLocalizedLabel("modalOkButton")}</button>`;
    }

    getPrvsrchId() {
        return "prvsrch-geolocation-disabled-modal";
    }

    addLocalizedLabels() {
        super.addLocalizedLabels();

        this.addLocalizedLabel("modalTitle", "Allow location access", "Autoriser l&rsquo;acc&egrave;s &agrave; mon emplacement");
        this.addLocalizedLabel("modalBody", "To use your current location, enable the location permission in your browser settings.", "Pour utiliser votre emplacement actuel, autorisez l&rsquo;acc&egrave;s &agrave; vos donn&eacute;es de localisation dans les param&egrave;tres de votre navigateur.");
    }
}
