'use strict';

import {PSWidgetClearFieldButton} from "../../clear-field-button";
import {PSWidgetTextField} from "../text-field";
import {Constants} from "../../constants";

export class PSWidgetFindField extends PSWidgetTextField {
    #fieldIdentifier = "widgetFindField";
    #findBackButtonElement;
    #textSearchPlaceholderText;
    #textSearchLabelText;

    constructor(language, uniqueId, textSearchPlaceholderText, textSearchLabelText) {
        super(language, uniqueId);

        this.#textSearchPlaceholderText = textSearchPlaceholderText;
        this.#textSearchLabelText = textSearchLabelText;
    }

    bindToDOM(parentDOMElement) {
        super.bindToDOM(parentDOMElement);

        this._textboxElement = this._domElement.querySelector('[data-lumino-widget-id="lumino-widget-searchfield-text"]');
        this._textboxWrapper = this._domElement.querySelector('[data-lumino-widget-id="lumino-widget-searchfield-text-wrapper-inner"]');
        this.#findBackButtonElement = this._domElement.querySelector('[data-lumino-widget-id="lumino-widget-textbox-back-btn"]');

        this._clearFieldComponent = new PSWidgetClearFieldButton(this._language, this.getClearSearchFieldLabel());
        this._clearFieldComponent.bindToDOM(this._textboxWrapper);

        this.bindEventListeners();
    }

    getClearSearchFieldLabel() {
        return this.getLocalizedLabel("clearAriaLabel").replace("{searchFieldLabel}", this.#textSearchLabelText);
    }

    inputValidation(dontShowErrors) {
        if(this.isEmptyField()) {
            if(!dontShowErrors) {
                this.renderErrorBox(this.getLocalizedLabel("validation_requiredField"));
            }
            return false;
        }

        if(!this.hasMinimumCharCount()) {

            if(!dontShowErrors) {
                this.renderErrorBox(this.getLocalizedLabel("validation_notEnoughCharacters"));
            }
            return false;
        }

        return true;
    }

    hasMinimumCharCount() {
        return this._textboxElement.value.trim().length >= Constants.SUGGESTION_MIN_INPUT_LENGTH;
    }

    getFieldIdentifier() {
        return this.#fieldIdentifier;
    }

    getTextboxBackButton() {
        return this.#findBackButtonElement;
    }

    getClearFieldButton() {
        return this._clearFieldComponent;
    }

    renderSearchIcon() {
        return `<div class="lumino-widget-search-icon" data-lumino-widget-id="lumino-widget-search-icon">${ this.getSearchSVGString() }</div>`;
    }

    getLabelElement() {
        return this._domElement.querySelector('[data-lumino-widget-id="lumino-widget-searchfield-text-label"]');
    }

    render() {
        return `<div data-lumino-widget-id="lumino-widget-searchfield-text-wrapper" class="lumino-widget-searchfield-text-wrapper">
                    <label class="lumino-widget-searchfield-text-label" data-lumino-widget-id="lumino-widget-searchfield-text-label" for="lumino-widget-searchfield-text-${ this._uniqueId }">${ this.#textSearchLabelText }</label>
                    <div class="lumino-widget-searchfield-text-wrapper-inner" data-lumino-widget-id="lumino-widget-searchfield-text-wrapper-inner">
                        ${ this.renderSearchIcon() }
                        ${ this.renderTextboxBackButton() }
                        <input type="text" maxlength="100" role="combobox" id="lumino-widget-searchfield-text-${ this._uniqueId }" aria-controls="lumino-suggestions-wrapper-${ this._uniqueId }" aria-autocomplete="list" data-lumino-widget-id="lumino-widget-searchfield-text" class="lumino-widget-searchfield-text" aria-expanded="false" placeholder="${ this.#textSearchPlaceholderText }" required=""> 
                    </div>
                    
                    <span class="prvsrch-sr-only" aria-live="polite" data-lumino-widget-id="lumino-widget-searchfield-sr-text"></span>
                </div>`;
    }

    getSuggestionCountSrLabel(suggestionCount) {
        return  suggestionCount + " " +  this.getLocalizedLabel("suggestionsSrLabel");
    }

    getSearchSVGString() {
        return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path class="cls-1" d="M17.75,15.57c.14,.18,.25,.39,.25,.6,0,.25-.11,.46-.25,.6l-.98,.98c-.18,.18-.39,.25-.6,.25-.25,0-.46-.07-.6-.25l-3.52-3.52c-.18-.14-.25-.35-.25-.6v-.56c-1.34,1.05-2.81,1.55-4.5,1.55-1.34,0-2.57-.32-3.69-.98-1.12-.63-2-1.51-2.64-2.64-.67-1.12-.98-2.36-.98-3.69s.32-2.53,.98-3.66C1.62,2.53,2.5,1.65,3.62,.98c1.12-.63,2.36-.98,3.69-.98s2.53,.35,3.66,.98c1.12,.67,2,1.55,2.67,2.67,.63,1.12,.98,2.32,.98,3.66,0,1.69-.53,3.2-1.55,4.5h.56c.21,0,.42,.11,.6,.25l3.52,3.52Zm-10.44-3.76c.81,0,1.55-.18,2.25-.6,.7-.39,1.23-.91,1.65-1.62,.39-.7,.6-1.44,.6-2.29s-.21-1.55-.6-2.25c-.42-.7-.95-1.23-1.65-1.65-.7-.39-1.44-.6-2.25-.6s-1.58,.21-2.29,.6c-.7,.42-1.23,.95-1.62,1.65-.42,.7-.6,1.44-.6,2.25s.18,1.58,.6,2.29c.39,.7,.91,1.23,1.62,1.62,.7,.42,1.44,.6,2.29,.6Z"></path></svg>`;
    }

    updateSuggestionSrText(numberOfSuggestions) {

        if (numberOfSuggestions > 0) {

            const suggestionSrText = this.getSuggestionCountSrLabel(numberOfSuggestions);
            let suggestionSrTextElement = this.getDOMElement().querySelector("[data-lumino-widget-id='lumino-widget-searchfield-sr-text']");

            if (suggestionSrTextElement.innerHTML === suggestionSrText) {
                suggestionSrTextElement.innerHTML = suggestionSrText + "&nbsp;";
            } else {
                suggestionSrTextElement.innerHTML = suggestionSrText;
            }

        }
    }

    suggestionsAreShown(numberOfSuggestions) {
        this.updateSuggestionSrText(numberOfSuggestions);
    }

    addLocalizedLabels() {
        this.addLocalizedLabel("clearAriaLabel","Clear {searchFieldLabel} field","Effacer le champ de {searchFieldLabel}");
        this.addLocalizedLabel("backAriaLabel","Back","Retour");

        this.addLocalizedLabel("validation_requiredField","Search field is required","Le champs Rechercher est obligatoire");
        this.addLocalizedLabel("validation_notEnoughCharacters","Enter 2 letters or more to get suggestions","Entrez 2 lettres ou plus pour avoir des suggestions");

        this.addLocalizedLabel("suggestionsSrLabel", "suggestions available. Select an option below.", "suggestions disponibles. Choisissez une option ci-dessous.");
    }
}
