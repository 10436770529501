'use strict';


export class LocationSuggestionViewModel {
    #placeId;

    #label;
    #labelMainText;
    #labelSubText;

    constructor(placeId, label, labelMainText, labelSubText) {
        this.#placeId = placeId;

        this.#label = label;
        this.#labelMainText = labelMainText;
        this.#labelSubText = labelSubText;
    }

    setPlaceId(value) {
        this.#placeId = value;
    }

    getPlaceId() {
        return this.#placeId;
    }

    setLabel(value) {
        this.#label = value;
    }

    getLabel() {
        return this.#label;
    }

    getLabelMainText() {
        return this.#labelMainText;
    }

    setLabelMainText(value) {
        this.#labelMainText = value;
    }

    getLabelSubText() {
        return this.#labelSubText;
    }

    setLabelSubText(value) {
        this.#labelSubText = value;
    }
}
