'use strict';

import {PSWidgetBasicComponent} from "./basic-component";

export class PSWidgetModal extends PSWidgetBasicComponent {
    #hasOpenedDropdown;
    #bypassModalOpenedEvent;

    constructor(locale = "", options = {}) {
        super(locale);

        this._triggeringElement = options.triggeringElement ?? null;
        this._cssWrapperClasses = options.cssWrapperClasses ?? "";
        this._backdropClickClose = options.backdropClickClose ?? true;
        this._keyboardEscClose = options.keyboardEscClose ?? true;
        this._modalSizeClass = options.modalSizeClass ?? "";

        this.#bypassModalOpenedEvent = options.bypassModalOpenedEvent ?? false;
    }

    bindToDOM(parentDOMElement) {
        super.prependToDOM(parentDOMElement);

        document.body.classList.add("prvsrch-modal-open");

        if(!this.#bypassModalOpenedEvent) {
            this.dispatchModalOpenedEvent();
        }

        this._domElement.addEventListener("prvsrchDropdownState", (e) => {
            // Used to check if we have an opened dropdown
            this.#hasOpenedDropdown = e.originalEvent.detail.prvsrchDropdownState;
        });

        this.getModalDialog().focus();

        this.getCloseButtons().forEach((item) => {
            item.addEventListener("click", (e) => {
                e.stopPropagation();
                this.closeModal();
            });
        });

        // WA - While on top most button, Shift+Tab keyboard press should move focus to bottom most modal button
        this._domElement.querySelector('[data-prvsrchid="prvsrch-modal-header"] [data-prvsrchid="prvsrch-modal-close"]').addEventListener("keydown", (e) => {
            if(e.key === "Tab" && e.shiftKey) {
                e.preventDefault();
                this._domElement.querySelector('[data-prvsrchid="prvsrch-modal-footer"] button:last-child').focus();
            }
        });

        // WA - While on bottom most button, Tab keyboard press should move focus to top most modal button
        this._domElement.querySelector('[data-prvsrchid="prvsrch-modal-footer"] button:last-child').addEventListener("keydown", (e) => {
            if(e.key === "Tab" && !e.shiftKey) {
                e.preventDefault();
                this._domElement.querySelector('[data-prvsrchid="prvsrch-modal-header"] [data-prvsrchid="prvsrch-modal-close"]').focus();
            }
        });

        if (this._keyboardEscClose) {
            this._domElement.addEventListener("keydown", (e) => {
                if (e.key === "Escape") {
                    if(this.#hasOpenedDropdown) {
                        e.stopPropagation();
                        // Don't close modal if we have an opened dropdown, priority goes to closing dropdown instead
                        return;
                    }

                    this.closeModal();
                }
            });
        }

        if (this._backdropClickClose) {
            this._domElement.addEventListener("click", (e) => {
                const modalElement = this._domElement.querySelector('[data-prvsrch-modal]');

                if (e.target !== modalElement) {
                    return;
                }

                this.closeModal();
            });
        }

        return this._domElement;
    }

    getCloseButtons() {
        return this._domElement.querySelectorAll('[data-prvsrchid="prvsrch-modal-close"]');
    }

    dispatchModalOpenedEvent() {
        window.dispatchEvent(new CustomEvent('providerSearchModalOpened', { bubbles: true, detail: { modalInstance: this} }));
    }

    closeModal() {
        this._domElement.dispatchEvent(new CustomEvent('providerSearchModalCloseRequested', { bubbles: true }));
        this.destroy();

        if (this._triggeringElement) {
            if(this._triggeringElement.currentTarget) {
                this._triggeringElement.currentTarget.focus();
            } else {
                this._triggeringElement.focus();
            }
        }

        document.body.classList.remove("prvsrch-modal-open");

        window.dispatchEvent(new CustomEvent('providerSearchModalClosed', { bubbles: true }));
    }

    getCloseSVGString() {
        return `<svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 9.18 9.18"><title>${this.getLocalizedLabel("modalCloseTitle")}</title><defs></defs><g><g><g ><g><g><g><path class="cls-1" d="M.76.76,8.41,8.41"/></g></g></g></g></g></g><g><g><g><g><g><g><path class="cls-1" d="M.76,8.41,8.41.76"/></g></g></g></g></g></g></svg>`;
    }

    renderHeader() {
        return `<h3 data-prvsrchid="prvsrch-modal-title">${this.getLocalizedLabel("modalTitle")}</h3>
                <button type="button" class="prvsrch-btn-close" data-prvsrchid="prvsrch-modal-close" aria-label="${this.getLocalizedLabel("modalCloseTitle")}">
                    ${ this.getCloseSVGString() }
                </button>`;
    }

    renderBody() {
        // Abstract
        return ``;
    }

    renderFooter() {
        // Abstract
        return ``;
    }

    getModalDialog() {
        return this._domElement.querySelector('[data-prvsrchid="prvsrch-modal-dialog"]');
    }

    getModalHeader() {
        return this._domElement.querySelector('[data-prvsrchid="prvsrch-modal-header"]');
    }

    getModalBody() {
        return this._domElement.querySelector('[data-prvsrchid="prvsrch-modal-body"]');
    }

    getModalFooter() {
        return this._domElement.querySelector('[data-prvsrchid="prvsrch-modal-footer"]');
    }

    getPrvsrchId() {
        // Abstract - Required field
        throw 'getPrvsrchID() cannot be empty, please return a valid value!';
    }

    render() {
        const prvsrchId = this.getPrvsrchId();

        return `<div class="prvsrch-styles-wrapper">
                    <div class="prvsrch-modal ${this._cssWrapperClasses} ${this._modalSizeClass}" data-prvsrchid="${prvsrchId}" data-prvsrch-modal>
                        <div class="prvsrch-modal-dialog" data-prvsrchid="prvsrch-modal-dialog" tabindex="-1" role="dialog" aria-label="${this.getLocalizedLabel("modalTitle")}">
                            <span class="prvsrch-sr-only">${this.getLocalizedLabel("modalStartSRText")}</span>
                        
                            <div class="prvsrch-modal-header" data-prvsrchid="prvsrch-modal-header">
                                ${this.renderHeader()}
                            </div>
                            
                            <div class="prvsrch-modal-body" data-prvsrchid="prvsrch-modal-body">
                                ${this.renderBody()}
                            </div>
                            
                            ${this.renderFooter()
                            ? `<div class="prvsrch-modal-footer" data-prvsrchid="prvsrch-modal-footer">
                                   ${this.renderFooter()}
                               </div>`
                            : ``}
                            
                            <span class="prvsrch-sr-only">${this.getLocalizedLabel("modalEndSRText")}</span>
                        </div>
                    </div>
                </div>`;
    }

    addLocalizedLabels() {
        this.addLocalizedLabel("modalStartSRText", "Start of dialog window", "D\u00e9but de la fen\u00eatre de dialogue");
        this.addLocalizedLabel("modalEndSRText", "End of dialog window", "Fin de la fen\u00eatre de dialogue");
        this.addLocalizedLabel("modalCloseTitle", "Close", "Fermer");
        this.addLocalizedLabel("modalCloseButton", "Close", "Fermer");
        this.addLocalizedLabel("modalDoneButton", "Done", "Termin&eacute;");
        this.addLocalizedLabel("modalApplyButton", "Apply", "Appliquer");
        this.addLocalizedLabel("modalOkButton", "OK", "OK");
        this.addLocalizedLabel("modalResetButton", "Reset", "R&eacute;initialiser");
        this.addLocalizedLabel("modalCancelButton", "Cancel", "Annuler");
        this.addLocalizedLabel("modalContinueButton", "Continue", "Continuer");
        this.addLocalizedLabel("modalYesButton", "Yes", "Oui");
        this.addLocalizedLabel("modalNoButton", "No", "Non");
        this.addLocalizedLabel("modalSubmitButton", "Submit", "Soumettre");
    }
}