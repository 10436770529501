'use strict';
import {PSWidgetBasicComponent} from "./basic-component";

export class PSWidgetSearchButton extends PSWidgetBasicComponent {

    constructor(language) {
        super(language);
    }

    bindToDOM(parentDOMElement) {
        super.bindToDOM(parentDOMElement);

        this._domElement.addEventListener('click', (e) => {
            e.preventDefault();
            this._domElement.dispatchEvent(new CustomEvent('executeSearch', { bubbles: true }));
        });
    }

    render() {
        return `<button type="submit" data-lumino-widget-id="lumino-widget-search-button" class="btn btn-yellow lumino-widget-search-button">
                    <span>${this.getLocalizedLabel("search_label")}</span>
                </button>`;
    }

    addLocalizedLabels() {
        this.addLocalizedLabel("search_label","Search","Rechercher");
    }
}
