'use strict';
import {PSWidgetBasicComponent} from "../basic-component";
import {Utils} from "../utils";

export class PSWidgetSuggestionsDropdown extends PSWidgetBasicComponent {
    #suggestionHighlightIndex;
    #suggestionButtonElements;

    constructor(language, uniqueId) {
        super(language);

        this._uniqueId = uniqueId;
        this.#suggestionHighlightIndex = -1;
    }

    bindToDOM(parentDOMElement) {
        super.bindToDOM(parentDOMElement);

        this.resizeSuggestionDropdownHeightBasedOnVisibleWindow();
        this.bindDropdownMouseCloseListener();
    }

    updateListOfSuggestionButtons() {
        this.#suggestionButtonElements = this.getListOfHighlightableButtons();
    }

    destroy() {
        document.removeEventListener("mousedown", this.closeDropdownClickHandler);

        super.destroy();
    }

    closeDropdownClickHandler = this.closeDropdownWhenClickingOutside.bind(this);

    getSuggestionsDropdownWrapperElement() {
        return this._domElement;
    }

    resizeSuggestionDropdownHeightBasedOnVisibleWindow() {
        // This will set the height of the suggestions dropdown to be the max-height between the Find field and the bottom of the window (- padding)
        if(Utils.isMobilePhone()) {
            return;
        }

        const topOfSuggestionsPosition = this.getDOMElement().getBoundingClientRect().top;
        const bottomPadding = 20;
        let maxDropdownHeight = window.innerHeight - topOfSuggestionsPosition - bottomPadding;

        if(maxDropdownHeight < this.getMinimumDropdownHeight()) {
            // Scroll the window slightly so entire minDropdownHeight can show on screen
            window.scrollTo(0, window.scrollY - maxDropdownHeight + this.getMinimumDropdownHeight());

            // Don't let dropdown become shorter than minDropdownHeight
            maxDropdownHeight = this.getMinimumDropdownHeight();
        }

        this.getDOMElement().style.maxHeight = maxDropdownHeight + "px";
    }

    getMinimumDropdownHeight() {
        // Override me
    }

    bindDropdownMouseCloseListener() {
        document.addEventListener("mousedown", this.closeDropdownClickHandler);
    }

    hasHighlightedSuggestion() {
        return this.#suggestionHighlightIndex >= 0;
    }

    suggestionWasSelected(suggestion) {
        this.getDOMElement().dispatchEvent(new CustomEvent('suggestionClicked', {bubbles: true, detail: suggestion}));
    }

    navigateToNextSuggestion() {
        this.#suggestionHighlightIndex++;

        if (this.#suggestionHighlightIndex >= this.#suggestionButtonElements.length) {
            this.#suggestionHighlightIndex = 0;
        }

        this.highlightSuggestionStyle(this.#suggestionHighlightIndex);
    }

    navigateToPreviousSuggestion() {
        this.#suggestionHighlightIndex--;

        if (this.#suggestionHighlightIndex < 0) {
            this.#suggestionHighlightIndex = (this.#suggestionButtonElements.length - 1);
        }

        this.highlightSuggestionStyle(this.#suggestionHighlightIndex);
    }

    highlightSuggestionStyle(suggestionHighlightIndex) {

        if (!this.#suggestionButtonElements) return false;

        this.removeSuggestionStyle();
        this.#suggestionButtonElements[suggestionHighlightIndex].classList.add("highlighted-suggestion");

        this.getDOMElement().dispatchEvent(new CustomEvent('setActiveAriaDescendant', {bubbles: true, detail: this.#suggestionButtonElements[this.#suggestionHighlightIndex].getAttribute("id") }));
        const suggestionsDropdownWrapper = this.getSuggestionsDropdownWrapperElement();
        if (this.#suggestionButtonElements[suggestionHighlightIndex].offsetTop < suggestionsDropdownWrapper.scrollTop) {
            // this is edge case when user press down arrow key from the last suggestion, it should bring user to the top of the list
            if (suggestionHighlightIndex === 0) {
                suggestionsDropdownWrapper.scrollTop = this.#suggestionButtonElements[suggestionHighlightIndex].offsetTop - this.#suggestionButtonElements[0].offsetTop;
                return;
            }
            suggestionsDropdownWrapper.scrollTop = this.#suggestionButtonElements[suggestionHighlightIndex].offsetTop;

        } else if (this.#suggestionButtonElements[suggestionHighlightIndex].offsetTop > (suggestionsDropdownWrapper.scrollTop + suggestionsDropdownWrapper.clientHeight) - this.#suggestionButtonElements[suggestionHighlightIndex].clientHeight) {
            suggestionsDropdownWrapper.scrollTop = this.#suggestionButtonElements[suggestionHighlightIndex].offsetTop - (suggestionsDropdownWrapper.clientHeight - this.#suggestionButtonElements[suggestionHighlightIndex].clientHeight);
        }
    }

    removeSuggestionStyle() {
        for (let i = 0; i < this.#suggestionButtonElements.length; i++) {
            this.#suggestionButtonElements[i].classList.remove("highlighted-suggestion");
        }
    }

    getSuggestionsCount() {
        return this.getListOfHighlightableButtons().length;
    }

    getListOfHighlightableButtons() {
        // Override me
    }

    render() {
        // Override me
    }
}
