'use strict';

import {Constants} from "../../constants";
import {Utils} from "../../utils";

export class FindSuggestionsViewModel {

    #sections = [];
    #sectionsByType = [];
    #hint;

    getSections() {
        return this.#sections;
    }

    getHint() {
        return this.#hint;
    }

    setHint(hint) {
        this.#hint = hint;
    }

    addSection(section) {
        this.#sections.push(section);
        this.#sectionsByType[section.getType()] = section;
    }

    getValuesForSectionType(sectionType) {

        const values = [];
        const section = this.#sectionsByType[sectionType];
        if(!section) {
            return values;
        }
        const suggestions = section.getSuggestions();
        for (let suggestionIndex = 0; suggestionIndex <= suggestions.length - 1; suggestionIndex++) {
            values.push(suggestions[suggestionIndex].getValue());
        }

        return values;
    }

    getSuggestionForSectionTypeAndValue(sectionType, value) {

        const section = this.#sectionsByType[sectionType];
        if(!section) {
            return null;
        }

        const suggestions = section.getSuggestions();
        let suggestion = null;
        let suggestionIndex = 0;
        while (!suggestion && suggestionIndex <= suggestions.length - 1) {

            if(suggestions[suggestionIndex].getValue() === value) {
                suggestion = suggestions[suggestionIndex];
            }

            suggestionIndex++;
        }

        return suggestion;
    }

    getExactMatchSuggestionToUseForSearch(searchText) {

        // if the only suggestion is search by name, then search by name
        // else if there is only one suggestion other than search by name which label matches exactly the searched text,
        // search by that suggestion

        let searchByNameSuggestion = null;
        let exactMatchSuggestion = null;
        let exactMatchingSuggestionsCount = 0;
        let totalSuggestions = 0;

        // go through the sections
        const sections = this.getSections();
        for(let sectionIndex = 0; sectionIndex <= sections.length - 1; sectionIndex++) {

            // only consider sections that are based on the user input
            const section = sections[sectionIndex];
            if(section.isBasedOnUserInput()) {

                // go through the suggestions of that section
                const suggestions = section.getSuggestions();
                for (let suggestionIndex = 0; suggestionIndex <= suggestions.length - 1; suggestionIndex++) {

                    totalSuggestions++;
                    const suggestion = suggestions[suggestionIndex];

                    if (suggestion.getType() === Constants.SUGGESTION_TYPE_NAME) {

                        // keep the name suggestion in memory in case we need it
                        searchByNameSuggestion = suggestion;
                    } else {

                        const normalizedSearchText = Utils.normalizeString(searchText).toLowerCase();
                        const normalizedSuggestionText = Utils.normalizeString(suggestion.getLabel()).toLowerCase();
                        // check if we have an exactly matching suggestion
                        if(normalizedSearchText.localeCompare(normalizedSuggestionText) === 0) {

                            if(!exactMatchSuggestion) {
                                exactMatchSuggestion = suggestion;
                            }

                            exactMatchingSuggestionsCount++;
                        }
                    }
                }
            }
        }

        // as soon as there is more than 1 exactly matching suggestions other than name, don't infer anything, return null
        if(exactMatchingSuggestionsCount > 1) {
            return null;
        }

        // if there was only one matching suggestion, use that one
        if(exactMatchSuggestion) {
            return exactMatchSuggestion;
        }

        // if no match and there is more than just the name suggestion, don't infer anything and just return null;
        if(totalSuggestions > 1) {
            return null;
        }

        // there was only one suggestion, and it was the suggestion by name, so use that one
        return searchByNameSuggestion;
    }
}
