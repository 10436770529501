'use strict';

import {v4 as uuidv4} from 'uuid';
import {Constants} from "./constants";

window.providerSearchLauncherApp = {
    getController: function() {
        return Utils._controller;
    }
};

export const Utils = {

    _controller: null,

    setController: function(value) {
        this._controller = value;
    },

    getController: function() {
        return this._controller;
    },

    isXS: function () {
        return this.isViewportAtMost(768);
    },

    isSM: function () {
        return this.isViewportAtMost(992);
    },

    isMD: function () {
        return this.isViewportAtMost(1200);
    },

    generateUUID: function() {
        return uuidv4();
    },

    // checks whether string represents a numeric value
    isNumeric(addressString) {
        return !isNaN(parseFloat(addressString)) && isFinite(addressString);
    },

    isMobilePhone() {
        return (this.isXS() && !this.isLandscapeOrientation()) || (this.isSM() && this.isLandscapeOrientation());
    },

    isLandscapeOrientation: function() {
        if(!!window.screen && !!window.screen.orientation && !!window.screen.orientation.type) {
            return (window.screen.orientation.type==="landscape-primary" || window.screen.orientation.type==="landscape-secondary");
        }
        // Deprecated function, look into using "screen.orientation" once iOS supports it
        return window.orientation === -90 || window.orientation === 90;
    },

    isElementOffScreen(el) {
        const rect = el.getBoundingClientRect();
        return (
            (rect.x + rect.width) < 0
            || (rect.y + rect.height) < 0
            || (rect.x > window.innerWidth || rect.y > window.innerHeight)
        );
    },

    isFrench: function (localeCode) {
        return localeCode === Constants.LANG_FR;
    },

    capitalizeFirstLetter(string) {
        if(!string) {
            return "";
        }

        return string.charAt(0).toUpperCase() + string.slice(1);
    },

    removeUnitNumberInAddress: function(address) {

        if (!address) {
            return "";
        }

        const addressParts = address.split(" ");
        if (addressParts.length === 0) {
            return address;
        }

        // fetch the first part of the address
        let beginning = addressParts[0];

        // remove any commas
        beginning = beginning.replace(",", "");

        // split by dash to identify if there is potentially a street number AND a unit number
        const beginningParts = beginning.split("-");

        //if there is no dash then there is no unit number, all good, just return
        if (beginningParts.length <= 1) {
            return address;
        }

        // if the second part of the address doesn't contain the street number,
        // then it might be a street or city, so we don't do anything
        if (!this.isNumeric(beginningParts[1])) {
            return address;
        }

        // the second part of the beginning is a street number, so the first part must be a unit
        // remove the unit number and reset the location
        address = address.substring(beginningParts[0].length + 1);

        return address;
    },

    isViewportAtMost: function(mobile_breakpoint) {
        if (!mobile_breakpoint) {
            mobile_breakpoint = 1;
        }

        const viewportWidth = top.window.innerWidth || top.document.documentElement.clientWidth || top.document.body.clientWidth;
        return viewportWidth < mobile_breakpoint;
    },

    normalizeString: function(string) {
        return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },

    sanitizeString: function(string) {
        if(!string) {
            return string;
        }

        return string.replace(/[?;:<>\[\]{}"]/g, '');
    },

    removeSpecialChars: function(string) {
        if(!string) {
            return string;
        }

        return string.replace(/[^a-zA-Z0-9-_]/g,'');
    },

    debounce(cb, delay = 500) {
        let timeout;

        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                cb(...args);
            }, delay);
        }
    },

    throttle(cb, delay = 500) {
        let shouldWait = false;
        let waitingArgs;

        const timeoutFunc = () => {
            if(waitingArgs == null) {
                shouldWait = false;
            } else {
                cb(...waitingArgs);
                waitingArgs = null;
                setTimeout(timeoutFunc, delay);
            }
        };

        return (...args) => {
            if(shouldWait) {
                waitingArgs = args;
                return;
            }

            cb(...args);
            shouldWait = true;

            setTimeout(timeoutFunc, delay);
        }
    },

    decodeHtml: function (input) {
        // Convert HTML Chars into ASCII equivalent
        // ex: convert &#31; into '
        if (!input) {
            return;
        }

        return new DOMParser().parseFromString(input, "text/html").documentElement.textContent;
    },
}
