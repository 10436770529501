'use strict';
import {Constants} from "../../constants";
import {PSWidgetSuggestionsDropdown} from "../suggestions-dropdown";
import {Utils} from "../../utils";

export class PSWidgetFindSuggestionsDropdown extends PSWidgetSuggestionsDropdown {
    #suggestionsViewModel;
    #minimumDropdownHeight = 400;

    constructor(language, uniqueId, suggestionsViewModel) {
        super(language, uniqueId);

        this.#suggestionsViewModel = suggestionsViewModel;
    }

    bindToDOM(parentDOMElement) {
        super.bindToDOM(parentDOMElement);

        this.updateListOfSuggestionButtons();
        this.bindEventListeners();
    }

    getMinimumDropdownHeight() {
        return this.#minimumDropdownHeight;
    }

    closeDropdownWhenClickingOutside(e) {
        if(!e.target.closest('[data-lumino-widget-id="lumino-widget-find-combobox"]')) {
            this._domElement.dispatchEvent(new CustomEvent('closeSearchSuggestions', { bubbles: true }));
        }
    }

    bindEventListeners() {
        const suggestionButtonsArray = this.getListOfSuggestionButtons();
        if(!suggestionButtonsArray) {
            return;
        }

        suggestionButtonsArray.forEach((item) => {
            const value = item.getAttribute("data-lumino-suggestion-value");
            const section = item.getAttribute("data-lumino-suggestion-section");
            const suggestion = this.#suggestionsViewModel.getSuggestionForSectionTypeAndValue(section, value);

            item.addEventListener('click', event => {
                event.preventDefault();
                this.suggestionWasSelected(suggestion);
            });
        });
    }

    highlightedSuggestionWasSelected() {

        const highlightedSuggestion = this._domElement.querySelector(".highlighted-suggestion");

        if (highlightedSuggestion) {
            let value = highlightedSuggestion.getAttribute("data-lumino-suggestion-value");
            let section = highlightedSuggestion.getAttribute("data-lumino-suggestion-section");
            const suggestion = this.#suggestionsViewModel.getSuggestionForSectionTypeAndValue(section, value);
            this.suggestionWasSelected(suggestion);
        }
    }

    suggestionWasSelected(suggestion) {
        this.getDOMElement().dispatchEvent(new CustomEvent('suggestionClicked', {bubbles: true, detail: suggestion}));
    }

    renderSuggestion(suggestionViewModel, index) {
        const buttonId = `${suggestionViewModel.getSection()}_${Utils.removeSpecialChars(suggestionViewModel.getValue())}_${index}_${ this._uniqueId }`;
        return `
                 <button class="lumino-suggestions-section-suggestion"  role="option"
                     data-lumino-widget-id="lumino-suggestions-section-suggestion" 
                     data-lumino-suggestion-value="${suggestionViewModel.getValue()}"
                     data-lumino-suggestion-section="${suggestionViewModel.getSection()}"
                     id="${buttonId}"
                 >${suggestionViewModel.getLabel()}</button>`;
    }

    renderSection(section) {
        if(!section?.getSuggestions() || section?.getSuggestions().length === 0) {
            return ``;
        }


        let sectionHtml = `<div data-lumino-widget-id="lumino-suggestions-section" role="group" class="lumino-suggestions-section" aria-labelledby="${ section.getType()}_${ this._uniqueId }">
                                <div class="lumino-suggestions-section-title" id="${section.getType()}_${ this._uniqueId }">${section.getLabel()}</div>
          `;

        let suggestions = section.getSuggestions();
        suggestions.forEach((suggestion, index) => {
            sectionHtml += this.renderSuggestion(suggestion, index);
        });

       sectionHtml += `</div>`;

        return sectionHtml;
    }

    setSuggestionHintAriaAttribute(searchedText) {

        if (searchedText.length === 0) {
            return;
        }

        const suggestionHintElement = this._domElement.querySelector("[data-lumino-widget-id='lumino-suggestions-hint']");

        if (searchedText.length < Constants.SUGGESTION_MIN_INPUT_LENGTH) {
            suggestionHintElement.setAttribute("aria-hidden", true);
        } else {
            suggestionHintElement.removeAttribute("aria-hidden");
        }
    }

    getListOfSuggestionButtons() {
        return this._domElement.querySelectorAll('[data-lumino-widget-id="lumino-suggestions-section-suggestion"]');
    }

    getListOfHighlightableButtons() {
        return this._domElement.querySelectorAll("[data-lumino-widget-id='lumino-suggestions-section-suggestion']");
    }

    renderSections() {
        let sections = ``;

        this.#suggestionsViewModel.getSections().forEach((section) => {
            sections += this.renderSection(section);
        });

        return sections;
    }

    renderHint() {
        if(!this.#suggestionsViewModel) {
            return ``;
        }

        if(!this.#suggestionsViewModel.getHint()) {
            return ``;
        }

        return `<div class="lumino-suggestions-hint" data-lumino-widget-id="lumino-suggestions-hint">${this.#suggestionsViewModel.getHint()}</div>`
    }

    render() {

        return `<div data-lumino-widget-id="lumino-suggestions-parent" aria-labelledby="lumino-widget-searchfield-text-${ this._uniqueId }" class="lumino-suggestions-parent" role="listbox" id="lumino-suggestions-wrapper-${ this._uniqueId }" data-scroll-lock-scrollable>
                    ${this.renderHint()}
                    ${this.renderSections()}
                </div>`;
    }
}
