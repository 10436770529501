'use strict';

import {PSWidgetCombobox} from "../combobox";
import {PSWidgetFindField} from "./find-field";
import {PSWidgetFindSuggestionsDropdown} from "./find-suggestions-dropdown";
import {FindSuggestionsViewModelBuilder} from "./find-suggestions-view-model-builder";
import {FindSuggestionViewModel} from "./find-suggestion-view-model";
import {Constants} from "../../constants";

export class PSWidgetFindCombobox extends PSWidgetCombobox {
    #findComboboxElement;
    #findField;
    #findSuggestionsDropdown;
    #featureToggles;
    #findFieldPlaceholderText;
    #findFieldLabelText;

    #suggestionsData;
    #suggestionsViewModelBuilder;
    #selectedSuggestion = null;
    #lastSuggestionsViewModel;
    #exactMatchCurrentlyUsed;
    #useConfigSpecialtySorting;

    constructor(language, uniqueId, findFieldPlaceholderText, findFieldLabelText, featureToggles, suggestionsData, useConfigSpecialtySorting) {
        super(language, uniqueId);

        this.#findFieldPlaceholderText = findFieldPlaceholderText;
        this.#findFieldLabelText = findFieldLabelText;

        this.#featureToggles = featureToggles;
        this.#suggestionsData = suggestionsData;
        this.#useConfigSpecialtySorting = useConfigSpecialtySorting;
    }

    bindToDOM(parentDOMElement) {
        super.bindToDOM(parentDOMElement);

        this.#findComboboxElement = this._domElement;
        this.#suggestionsViewModelBuilder = this.initSuggestionsViewModelBuilder();

        this.#findField = new PSWidgetFindField(this._language, this._uniqueId, this.buildPlaceholderText(), this.buildLabelText());
        this.#findField.bindToDOM(this.#findComboboxElement);

        this.bindEventListeners();
    }

    bindEventListeners() {
        super.bindEventListeners();

        this.getInputField().getDOMElement().addEventListener('textInputTextChanged', (e) => {
            e.stopPropagation();
            this.onSearchFieldUserInput();

            this.getDOMElement().dispatchEvent(new CustomEvent('textInputTextChanged', {bubbles: true, detail: { inputType: e.detail.inputType }}));
        });

        this.getDOMElement().addEventListener('suggestionClicked', (e) => {
            this.suggestionWasClicked(e);
        });

        this.bindResizeEvent();
    }

    suggestionWasClicked(e) {

        const suggestion = e.detail;
        this.setSelectedSuggestion(suggestion);

        this.#findField.removeErrorBox();
        this.closeSuggestions(true, true);

        this.getDOMElement().dispatchEvent(new CustomEvent('findSuggestionClicked', {bubbles: true, detail: { suggestion: suggestion }}));
    }

    replaceLastCommaWithDisjunction(replacedString, disjunction) {
        const lastComma = replacedString.lastIndexOf(", ");
        if(lastComma !== -1) {
            replacedString = replacedString.slice(0, lastComma) + " " + disjunction + " " + replacedString.slice(lastComma + 2);
        }

        return replacedString;
    }

    buildPlaceholderText() {
        if(this.#findFieldPlaceholderText) {
            return this.#findFieldPlaceholderText;
        }

        const placeholder = [];
        if(this.#featureToggles.nameSearchEnabled) {
            placeholder.push(this.getLocalizedLabel("textfield_placeholder_name"));
        }

        if(this.#featureToggles.conditionSearchEnabled) {
            placeholder.push(this.getLocalizedLabel("textfield_placeholder_condition"));
        }

        if(this.#featureToggles.serviceSearchEnabled) {
            placeholder.push(this.getLocalizedLabel("textfield_placeholder_service"));
        }

        if(this.#featureToggles.specialtySearchEnabled) {
            placeholder.push(this.buildSpecialtyPlaceholderText());
        }

        let placeholderOutput = this.getLocalizedLabel("textfield_placeholder");
        placeholderOutput += placeholder.join(", ");

        return placeholderOutput;
    }

    buildSpecialtyPlaceholderText() {

        const specialtySuggestions = this.#suggestionsData.filter((suggestion) => {
            return suggestion.type === Constants.SUGGESTION_TYPE_SPECIALTY;
        });

        const sortedSpecialtySuggestions = specialtySuggestions.sort((a, b) => {
            const labelA = a.label.toLowerCase();
            const labelB = b.label.toLowerCase();
            if (labelA < labelB) {
                return -1;
            }
            if (labelA > labelB) {
                return 1;
            }

            return 0;
        });

        let specialtyPlaceholderText = "";
        for(const specialtySuggestion of sortedSpecialtySuggestions) {
            if(specialtySuggestion.code === Constants.FIND_FIELD_PLACEHOLDER_DEFAULT_SPECIALTY_CODE) {
                specialtyPlaceholderText = specialtySuggestion.label;
                break;
            }
        }

        // If the placeholder default specialty is not enabled, we get the first alphabetical specialty from the suggestions
        if(!specialtyPlaceholderText) {
            specialtyPlaceholderText = sortedSpecialtySuggestions[0].label;
        }

        return specialtyPlaceholderText.toLowerCase();
    }

    buildLabelText() {
        if(this.#findFieldLabelText) {
            return this.#findFieldLabelText;
        }

        const label = [];
        if(this.#featureToggles.nameSearchEnabled) {
            label.push(this.getLocalizedLabel("textfield_label_name"));
        }

        if(this.#featureToggles.conditionSearchEnabled) {
            label.push(this.getLocalizedLabel("textfield_label_condition"));
        }

        if(this.#featureToggles.serviceSearchEnabled) {
            label.push(this.getLocalizedLabel("textfield_label_service"));
        }

        if(this.#featureToggles.specialtySearchEnabled) {
            label.push(this.getLocalizedLabel("textfield_label_specialty"));
        }

        let labelOutput = this.getLocalizedLabel("textfield_label");
        labelOutput += label.join(", ");
        labelOutput = this.replaceLastCommaWithDisjunction(labelOutput, this.getLocalizedLabel("textfield_disjunction"));


        return labelOutput;
    }

    selectSuggestion(type, matchType, sectionType, code, labelForTextBox) {

        let suggestion = null;

        if(code) {
            if (labelForTextBox) {
                suggestion = new FindSuggestionViewModel(type, matchType, code, labelForTextBox, labelForTextBox, sectionType);
            } else {
                suggestion = this.#suggestionsViewModelBuilder.buildSuggestionFromCode(code, type, sectionType, matchType);
            }
        }

        this.setSelectedSuggestion(suggestion);
    }

    setSearchedText(value) {
        this.#findField.setTextFieldValue(value);
    }

    setSelectedSuggestion(suggestion) {
        this.#selectedSuggestion = suggestion;

        let label = "";
        if(suggestion) {
            label = suggestion.getTextForSearchBox();
        }
        this.#findField.setTextFieldValue(label);
    }

    getSelectedSuggestion() {
        return this.#selectedSuggestion;
    }

    resetSelectedSuggestion() {
        this.#selectedSuggestion = null;
        this.#exactMatchCurrentlyUsed = false;
    }

    getLastSuggestionsViewModel() {
        return this.#lastSuggestionsViewModel;
    }

    getExactMatchCurrentlyUsed() {
        return this.#exactMatchCurrentlyUsed;
    }

    getExactMatchSuggestionToUseForSearch(attempt) {
        const suggestionToUse = this.#lastSuggestionsViewModel.getExactMatchSuggestionToUseForSearch(this.getSearchedText());

        if(suggestionToUse) {
            this.#exactMatchCurrentlyUsed = true;
        }

        return suggestionToUse;
    }

    enterKeyWasPressedOnSuggestion() {

        if(this.getSuggestionsDropdown() && this.getSuggestionsDropdown().hasHighlightedSuggestion()) {
            this.getSuggestionsDropdown().highlightedSuggestionWasSelected();
        }
    }

    onSearchFieldUserInput() {
        this.resetSelectedSuggestion();
        this.closeSuggestions(false, false);
        this.showSuggestions();
    }

    initSuggestionsViewModelBuilder() {
        return new FindSuggestionsViewModelBuilder(this._language, this.#suggestionsData, this.#featureToggles, this.#useConfigSpecialtySorting);
    }

    showSuggestions() {
        this.#lastSuggestionsViewModel = this.#suggestionsViewModelBuilder.getSuggestionsForUserInput(this.getSearchedText());
        if(!this.#lastSuggestionsViewModel.getSections() || this.#lastSuggestionsViewModel.getSections().length === 0) {
            return;
        }

        this.#findSuggestionsDropdown = new PSWidgetFindSuggestionsDropdown(this._language, this._uniqueId, this.#lastSuggestionsViewModel);
        this.#findSuggestionsDropdown.bindToDOM(this.getDOMElement());

        this.#findField.suggestionsAreShown(this.#findSuggestionsDropdown.getSuggestionsCount());
        this.#findSuggestionsDropdown.setSuggestionHintAriaAttribute(this.#findField.getTextFieldValue());

        this.#findSuggestionsDropdown.getDOMElement().addEventListener("closeSearchSuggestions", () => {
            this.closeSuggestions(false, true);
        });

        this._domElement.parentElement.classList.add("opened");
        this.getInputField().updateAttribute("aria-expanded", "true");
        this.updateSuggestionsWrapperScreenPosition();
    }

    onTextFieldComesIntoFocus() {
        if(this.getSuggestionsDropdown()) {
            return;
        }

        this.adjustForMobileLayoutOnFocus();
        this.showSuggestions();
    }

    getInputField() {
        return this.#findField;
    }

    getSuggestionsDropdown() {
        return this.#findSuggestionsDropdown;
    }

    setSuggestionsDropdown(value) {
        this.#findSuggestionsDropdown = value;
    }

    getTextboxElement() {
        return this.#findField.getDOMElement();
    }

    getErrorMessage() {
        return this.#findField.getErrorMessage();
    }

    isValidInput(dontShowErrors) {
        return this.#findField.isValidInput(dontShowErrors);
    }

    renderErrorBox(errorMsg) {
        return this.#findField.renderErrorBox(errorMsg);
    }

    removeErrorBox() {
        return this.#findField.removeErrorBox();
    }

    getSearchedText() {
        return this.#findField.getTextFieldValue();
    }

    render() {
        return `<div data-lumino-widget-id="lumino-widget-find-combobox" class="lumino-widget-find-combobox"></div>`;
    }

    addLocalizedLabels() {
        this.addLocalizedLabel("textfield_label","Search by ","Chercher par ");
        this.addLocalizedLabel("textfield_label_name","name","nom");
        this.addLocalizedLabel("textfield_label_condition","condition","probl&egrave;me");
        this.addLocalizedLabel("textfield_label_service","service","service");
        this.addLocalizedLabel("textfield_label_specialty","specialty","sp&eacute;cialit&eacute;");

        this.addLocalizedLabel("textfield_placeholder","E.g. ","Ex. : ");
        this.addLocalizedLabel("textfield_placeholder_name","John Jones","Jean Jasmin");
        this.addLocalizedLabel("textfield_placeholder_condition","neck pain","douleurs au cou");
        this.addLocalizedLabel("textfield_placeholder_service","eye exam","examen de la vue");

        this.addLocalizedLabel("textfield_disjunction","or","ou");
    }
}
