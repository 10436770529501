'use strict';

export const Constants = {
    LANG_EN: "en",
    LANG_FR: "fr",

    GOOGLE_API_VERSION: "quarterly",
    FUZZY_MATCH_SCORE: 0.2,

    SEARCH_MODE_SPECIALTY: "SPECIALTY",
    SEARCH_MODE_NAME: "NAME",
    SEARCH_MODE_CONDITION: "CONDITION",
    SEARCH_MODE_SERVICE: "SERVICE",
    SEARCH_MODE_FAVOURITES: "FAVOURITES",

    THEME_SECURE: "SECURE", // Used to enable "Secure" channel styles

    SEARCH_ACTION_TYPE_FORM_ACTION: "FORM", // Used on Secure pages (PPHP)
    SEARCH_ACTION_TYPE_REDIRECT: "REDIRECT", // Used on Lumino pages
    SEARCH_ACTION_TYPE_EVENT: "EVENT", // Used directly on Provider Search

    SUGGESTION_MIN_INPUT_LENGTH: 2,
    SUGGESTION_TYPE_SPECIALTY: "SPECIALTY",
    SUGGESTION_TYPE_NAME: "NAME",
    SUGGESTION_TYPE_CONDITION: "CONDITION",
    SUGGESTION_TYPE_SERVICE: "SERVICE",

    SUGGESTION_MATCH_TYPE_LABEL: "LABEL",
    SUGGESTION_MATCH_TYPE_RELATED_TERM: "RELATED_TERM",

    SUGGESTION_SECTION_ALL_SPECIALTIES: "ALL_SPECIALTIES",
    SUGGESTION_SECTION_EXACT_MATCH: "EXACT_MATCH",
    SUGGESTION_SECTION_SUGGESTED_SPECIALTIES: "SUGGESTED_SPECIALTIES",
    SUGGESTION_SECTION_SUGGESTED_CONDITIONS: "SUGGESTED_CONDITIONS",
    SUGGESTION_SECTION_SUGGESTED_SERVICES: "SUGGESTED_SERVICES",
    SUGGESTION_SECTION_SUGGESTED_NAME: "SUGGESTED_NAME",

    KEYWORD_EVENT_TRIGGER_SEARCH_EXECUTED: "SEARCH_EXECUTED",
    KEYWORD_EVENT_TRIGGER_DELETE_CHARACTERS: "DELETE_CHARACTERS",
    KEYWORD_EVENT_TRIGGER_EVERY_N_CHARACTERS: "EVERY_N_CHARACTERS",
    KEYWORD_EVENT_TRIGGER_LEAVE_PAGE: "LEAVE_PAGE",
    KEYWORD_EVENT_TRIGGER_MINIMUM_CHARACTERS: "MINIMUM_CHARACTERS",
    KEYWORD_EVENT_TRIGGER_PAUSE: "PAUSE",
    KEYWORD_EVENT_SUGGESTION_SELECTED: "SUGGESTION_SELECTED",

    SEARCH_ERROR_CODE_MISSING_LOCATION: 1,
    SEARCH_ERROR_CODE_INVALID_SEARCH_TERM: 2,
    SEARCH_ERROR_CODE_SUGGESTION_NOT_SELECTED: 3,

    FIND_FIELD_PLACEHOLDER_DEFAULT_SPECIALTY_CODE: "PHYSIO",

    SESSIONATTRIBUTE_MUM_PUBLIC_SESSION_ID: "mumPublicSessionId"
};
