'use strict';

import {PSWidgetBasicComponent} from "./basic-component";

export class PSWidgetGeolocationButton extends PSWidgetBasicComponent {
    constructor(language, uniqueId) {
        super(language);
        this._uniqueId = uniqueId;
    }

    bindToDOM(parentDOMElement) {
        super.bindToDOM(parentDOMElement);

        this._domElement.addEventListener('click', () => {
            this.onGeolocationClick();
        });
    }

    onGeolocationClick() {
        this.getDOMElement().dispatchEvent(new CustomEvent("geolocationClick", {bubbles: true}));
    }

    getGeolocationIconString() {
        return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.14 14.14"><path class="cls-1" d="M14.07,.93L7.64,13.79c-.11,.23-.3,.35-.57,.35-.03,0-.08,0-.15-.02-.15-.03-.27-.11-.36-.23-.09-.11-.13-.24-.13-.39V7.71H.64c-.15,0-.28-.04-.4-.13-.11-.09-.19-.21-.22-.36-.03-.15-.02-.29,.04-.42,.06-.13,.16-.23,.29-.3L13.21,.07c.09-.05,.18-.07,.29-.07,.18,0,.33,.06,.45,.19,.1,.09,.16,.21,.18,.35,.03,.13,0,.26-.06,.39Z"/></svg>`;
    }

    render() {
        return `<button data-lumino-widget-id="lumino-widget-geolocation-btn" class="lumino-widget-geolocation-btn" role="option" id="lumino-widget-geolocation-btn-${this._uniqueId}">
                    ${ this.getGeolocationIconString() }
                    <span>${ this.getLocalizedLabel("geolocation_label") }</span>
                </button>`;
    }

    addLocalizedLabels() {
        this.addLocalizedLabel("geolocation_label","Use my current location","Utiliser mon emplacement actuel");
    }
}
